import * as Yup from 'yup';
import { validString } from '../validators';

export const AcademicProgrammeSchema = Yup.object().shape({
    name: validString('programme name').required("Please enter a programme name"),
    image: Yup.string().required("Please choose an image"),
    programme: Yup.string().required("Please choose a programme"),
    summary: validString('summary', 'medium').required('Please enter the new programme summary'),
    content: validString('content', 'long'),
});

export const AcademicProgrammeInitialValues = {
    name: "",
    summary: "",
    content: "",
    image: "",
    programme: null,
};
