import * as Yup from 'yup';

export function validString(
  fieldName?: string,
  variant: 'short' | 'medium' | 'long' = 'short',
  min?: number, // overrides variant param
  max?: number  // overrides variant param
) {
  const variants = {
    'short': { min: 3, max: 50 },
    'medium': { min: 3, max: 500 },
    'long': { min: 3, max: 1000 },
  }
  return Yup.string()
    .min((min ? min : variants[variant].min), `${fieldName ? `${fieldName[0].toUpperCase() + fieldName.substring(1)}` : 'Value'} is too short`)
    .max((max ? max : variants[variant].max), `${fieldName ? `${fieldName[0].toUpperCase() + fieldName.substring(1)}` : 'Value'} is too long`)
}

export function validEmail(fieldName?: string): Yup.StringSchema {
  return Yup.string()
    .email('Please enter a valid email')
    .min(3, `${fieldName ? `${fieldName} e` : 'E'}mail is too short`)
    .max(50, `${fieldName ? `${fieldName} e` : 'E'}mail is too long`)
}
export function validPhone(fieldName?: string): Yup.StringSchema {
  return Yup.string()
    .min(10, `${fieldName ? `${fieldName} p` : 'P'}hone number is too short`)
    .max(13, `${fieldName ? `${fieldName} p` : 'P'}hone number is too long`)
    .matches(/^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, 'Please enter a valid phone number')  // I know, see: https://regex101.com/r/j48BZs/2
}
