import * as Yup from 'yup';
import { validString } from '../validators';

export const ResearchGroupSchema = Yup.object().shape({
  image: Yup.string().required('Please select an image'),
  name: validString('name').required('Please enter a name'),
  summary: validString('summary','medium').required('Please enter a descriptive summary'),
  content: validString('content', 'long').required('Please enter the research group details.')
});

