import { gql, useQuery } from "@apollo/client";
import { MultiCheckInput, RichTextArea, SelectInput, TextArea, TextInput, UploadArea } from "components/core";
import { SchoolPicker, DepartmentPicker } from "containers";
import { FormikProps } from "formik";
import { FC } from "react";

export interface AcademicProgrammeFormProps {
    form: FormikProps<{
        image: string;
        name: string;
        content: string;
        summary: string;
        programme: string | null;
    }>;
}

const GET_PROGRAMMES = gql`
query GetProgrammes($filter: ProgrammeFilter, $search: SearchOperator, $populate: [String], $pagination: Pagination) {
  items:getProgrammes(filter: $filter, search: $search, populate: $populate, pagination: $pagination) {
    _id
    name
  }
}
`;

const AcademicProgrammeForm: FC<AcademicProgrammeFormProps> = ({ form }) => {

    const { data, loading, networkStatus, refetch } = useQuery(GET_PROGRAMMES, {
        variables: {
            "filter": {
                "deleted": {
                    "eq": false
                }
            }
        },
        // pollInterval,
        fetchPolicy: "no-cache",
        notifyOnNetworkStatusChange: false,
    });
    // console.log({ data });

    return (
        <div className="space-y-6 divide-y divide-gray-200">
            <div>
                <span className="text-xs font-light">Academic Programme Information</span>
                <div className="grid grid-cols-1 gap-6 mt-2">

                    <div className=" col">
                        <UploadArea
                            id="image"
                            label="Image"
                            className="h-44"
                            maxFiles={1}
                            required
                            {...form}
                        />
                    </div>


                    <div>
                        <TextInput
                            id="name"
                            label="Name"
                            type="text"
                            required
                            placeholder="e.g. Mobile Application Development"
                            {...form}
                        />
                    </div>

                    <div className="space-y-1">
                        <TextArea
                            id="summary"
                            label="Summary"
                            placeholder="e.g. Understand the fundamentals of web technologies and create web-based applications with the latest technologies"
                            {...form}
                        />
                    </div>

                    <div className="space-y-1">
                        <RichTextArea
                            id="content"
                            label="Content"
                            placeholder="eg. Content area"
                            {...form}
                        />
                    </div>

                    <div className="space-y-1">
                        <SelectInput
                            options={data?.items?.map((programme: any) => ({
                                value: programme?._id,
                                label: programme?.name
                            }))}
                            id="programme"
                            label="Programme"
                            placeholder="eg. Programme"
                            required
                            {...form}
                        />
                    </div>

                </div>
            </div>
        </div>
    );
};

export default AcademicProgrammeForm;
