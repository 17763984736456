import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { classNames, withPermissions, wrapClick } from 'utils';
import {
  gql,
  useQuery,
  // useReactiveVar
} from '@apollo/client';
// import { currentConfigVar } from 'apollo/cache/config';
import { UserView, Modal } from 'components';
import { useSearch, useNavigate } from 'react-location';
import { LocationGenerics } from 'router/location';
import { GET_STAFF } from './update';
import StaffView from 'components/cards/staff-view';

export default function ViewStaffContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void }) {
  // const theme = useReactiveVar(currentConfigVar);

  const cancelButtonRef = useRef(null);
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { data, loading } = useQuery(GET_STAFF, {
    variables: {
      filter: {
        _id: {
          eq: searchParams.id
        }
      },
    },
    notifyOnNetworkStatusChange: false
  });
  
  const updateItem = () => {
    navigate({
      search: (old) => ({
        ...old,
        modal: "update"
      })
    })
  }

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Staff Information"
      description="Details of user are shown below"
      loading={loading}
      renderActions={() => (
        <>
        {withPermissions(["*:*", "users:*", "users:update"])(
          <button
            type="button"
            className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(updateItem)}
          >
            Edit
          </button>
        )}
        </>
      )}
    >
      <StaffView
        staff={data?.staff}
      />
    </Modal>
  )
}