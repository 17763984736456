import { FC,  } from 'react';
import { SearchSelectInput } from 'components/core';
import { Option } from 'components/core/search-select-input';

interface LevelPickerContainerProps {
    filter?: {
        school?: string;
    };
    id: string;
    label?: string;
    placeholder?: string;
    required?: boolean;
    disabled?: boolean;
    labelHidden?: boolean;
    values: any;
    errors?: any;
    touched?: any;
    setFieldValue: any;
    rawId?: boolean;
}

const levelsMap: Option[] = [
    {
        label: {
            title: "All Levels"
        },
        value: "All Levels"
    },
    {
        label: {
            title: "100"
        },
        value: "100"
    },
    {
        label: {
            title: "200"
        },
        value: "200"
    },
    {
        label: {
            title: "300"
        },
        value: "300"
    },
    {
        label: {
            title: "400"
        },
        value: "400"
    },
    {
        label: {
            title: "500"
        },
        value: "500"
    },
    {
        label: {
            title: "600"
        },
        value: "600"
    },
    {
        label: {
            title: "700"
        },
        value: "700"
    },
]


const LevelPickerContainer: FC<LevelPickerContainerProps> = ({ filter, id, label, rawId, ...form }) => {
    return (
        <SearchSelectInput
            id={id ?? "level"}
            label={label ?? "Level"}
            placeholder="Select Level"
            options={levelsMap}
            {...form}
            disabled={form.disabled}
        />
    );
};

export default LevelPickerContainer;