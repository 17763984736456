import ActionButton, { Action } from "components/buttons/action-button";
import { FC, useEffect, useMemo } from "react";
import { Header, Shimmers, TableComponent } from "components";
import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { useNavigate, useSearch } from "react-location";
import { useUrlState, withPermissions, wrapClick } from "utils";

import CreateAcademicProgrammeContainer from "./create";
import DeleteProgrammeContainer from "./delete";
import { LocationGenerics } from "router/location";
import { PlusIcon } from "@heroicons/react/24/outline";
import UpdateAcademicProgrammeContainer from "./update";
import ViewAcademicProgrammeContainer from "./view";
import _ from "lodash";
import config from "config";
import { currentConfigVar } from "apollo/cache/config";
import { currentUserVar } from "apollo/cache/auth";
import dayjs from "dayjs";
import lodash from "lodash";
import { trimTextToLength } from "utils/index";

const GET_PROGROMMES = gql`
query GetAcademicProgrammes($filter: AcademicProgrammeFilter, $pagination: Pagination, $search: SearchOperator, $populate: [String]) {
  items:getAcademicProgrammes(filter: $filter, pagination: $pagination, search: $search, populate: $populate) {
    _id
    # code
    programme {
      _id
      name
      code
    }
    createdAt
    name
    summary
  }
  itemsCount: getAcademicProgrammesCount(filter: $filter, search: $search)
}
`;

const AcademicProgrammesPage: FC = () => {
  const { pollInterval } = useReactiveVar(currentConfigVar);
  const currentUser = useReactiveVar(currentUserVar);
  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
      () => {
        navigate({
          search: (old) => ({
            ...old,
            id,
            modal: action,
          }),
        });
      };

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");
  const [school, setSchool] = useUrlState("school");

  const variables = useMemo(
    () => ({
      pagination: {
        limit: searchParams.pageSize || 12,
        skip: (searchParams.pageSize || 12) * ((searchParams.page || 1) - 1),
      },
      ...((searchParams?.search || "")?.length > 0
        ? {
          search: {
            query: searchParams?.search,
            fields: ["name", "summary"],
            options: "CaseInsensitive"
          },
        }
        : {}),
      filter: {
        ...(school && { school: { eq: school } }),
        deleted: {
          eq: false
        }
      },
      sort: {},
      populate: ["programme"],
    }),
    [searchParams]
  );


  const { data, loading, networkStatus, refetch } = useQuery(GET_PROGROMMES, {
    variables,
    // pollInterval,
    notifyOnNetworkStatusChange: false,
  });

  console.log({ data })

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? { search: "" } : {}),
        ...(!old?.sort ? { sort: "createdAt" } : {}),
      }),
    });
  }, [navigate]);

  return (
    <main className="flex-1 flex flex-col overflow-hidden h-screen bg-gray-50">
      <Header
        renderActions={() => (
          <>
            {withPermissions(["*:*", "programmes:*", "programmes:create"])(
              <button
                type="button"
                onClick={wrapClick(() => setModal("create"))}
                className="ml-3 inline-flex items-center rounded border border-transparent bg-primary-600 px-3 py-2 text-sm leading-4 text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
              >
                <PlusIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                Add Academic Programme
              </button>
            )}
          </>
        )}
      />
      <div className="flex flex-1 overflow-y-auto">
        <div className="flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
          <TableComponent
            title={"academic programmes"}
            refetch={refetch}
            loading={loading}
            data={{
              rows: lodash.get(data, "items", []),
              total: lodash.get(data, "itemsCount", 0),
              page: searchParams?.page || config.constants.page,
              pageSize: searchParams?.pageSize || config.constants.pageSize,
              totalPages: lodash.ceil(
                lodash.get(data, "itemsCount", 0) /
                (searchParams?.pageSize || config.constants.pageSize)
              ),
            }}
            hasSearch={true}
            renderExport={({ exportOpen, setExportOpen }) => (
              <></>
              // <ExportDataContainer
              //   open={exportOpen}
              //   setOpen={setExportOpen}
              //   entityType="Programme"
              //   variables={variables}
              // />
            )}
            renderHeaderItems={() => (
              <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex space-x-2">

              </div>
            )}
            renderColumns={() => (
              <tr>

                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Summary
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-center text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Programme
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Created At
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                ></th>
              </tr>
            )}
            renderLoader={() => (
              <tr>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.ActionsShimmer actionsCount={2} />
                </td>
              </tr>
            )}
            renderItem={(item) => (
              <tr
                key={item._id}
                className="hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer"
                onClick={wrapClick(dispatchAction(item._id, "view"))}
              >

                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="font-medium text-gray-900 dark:text-gray-100">
                    {item?.name || "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-500 dark:text-gray-100">
                    {trimTextToLength(item?.summary, 50) || "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="font-medium text-gray-900 text-center dark:text-gray-100">
                    {item?.programme?.name || "N/A"}
                  </div>
                  <div className="font-medium text-gray-400 text-center dark:text-gray-100">
                    {item?.programme?.code || "N/A"}
                  </div>
                </td>


                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-500 dark:text-gray-100">
                    {item?.createdAt
                      ? dayjs(item?.createdAt).format("DD/MM/YYYY hh:mm A")
                      : "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right">
                  <div className="space-x-1">
                    <ActionButton
                      action="view"
                      onClick={dispatchAction(item?._id, "view")}
                    />
                    {withPermissions([
                      "*:*",
                      "programmes:*",
                      "programmes:update",
                    ])(
                      <ActionButton
                        action="update"
                        onClick={dispatchAction(item?._id, "update")}
                      />
                    )}
                    {withPermissions([
                      "*:*",
                      "programmes:*",
                      "programmes:delete",
                    ])(
                      <ActionButton
                        action="delete"
                        onClick={dispatchAction(item?._id, "delete")}
                      />
                    )}
                  </div>
                </td>
              </tr>
            )}
          />
        </div>
      </div>

      {withPermissions(["*:*", "programmes:*", "programmes:create"])(
        <CreateAcademicProgrammeContainer
          open={modal === "create"}
          setOpen={(val: boolean) => setModal(val ? "create" : undefined)}
          refetch={refetch}
          loading={loading}
        />
      )}
      {!!searchParams.id?.length && (
        <>
          {withPermissions(["*:*", "programmes:*", "programmes:update"])(
            <UpdateAcademicProgrammeContainer
              open={modal === "update"}
              setOpen={(val: boolean) => setModal(val ? "update" : undefined)}
              refetch={refetch}
            />
          )}
          {withPermissions(["*:*", "programmes:*", "programmes:delete"])(
            <DeleteProgrammeContainer
              open={modal === "delete"}
              setOpen={(val: boolean) => setModal(val ? "delete" : undefined)}
              refetch={refetch}
            />
          )}
          <ViewAcademicProgrammeContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
          />
        </>
      )}
    </main>
  );
};

export default AcademicProgrammesPage;
