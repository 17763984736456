import { gql, useQuery, useReactiveVar } from '@apollo/client'
import { FC, useEffect } from 'react';
import { SearchSelectInput } from 'components/core';
import { currentUserVar } from 'apollo/cache/auth';

interface UserPickerContainerProps {
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any
  setFieldValue: any;
  rawId?: boolean;
  filter?: any
}

const GET_USERS = gql`
query GetUsers($filter: UserFilter) {
  users: getUsers(filter: $filter, pagination: { skip: 0 limit: 0}) {
    _id
    name
    code
  }
}
`;

const UsersPickerContainer: FC<UserPickerContainerProps> = ({ id, label, rawId, filter, ...form }) => {
  const currentUser = useReactiveVar(currentUserVar);
  const { loading, data } = useQuery(GET_USERS, {
    notifyOnNetworkStatusChange: false, fetchPolicy: 'no-cache',
    variables: {
      filter: {
        ...(filter ? filter : {}),
      }
    }
  })
  
  useEffect(() => {
    if (currentUser?.department) {
      form?.setFieldValue?.(id, rawId ? currentUser.department?._id : {
        _id: currentUser.department._id,
        code: currentUser.department.code,
        name: currentUser.department.name,
      })
    }
  }, [currentUser])

  return (
    <SearchSelectInput
      id={id ?? "user"}
      label={label ?? "user"}
      placeholder="Select User"
      optionsLoading={loading}
      options={(data?.users ?? [])?.map((user: any) => ({
        label: {
          title: user.name as string,
        },
        value: rawId ? user?._id : user
      }))}
      {...form}
      disabled={form.disabled || !!(currentUser.department)}
    />
  )
}

export default UsersPickerContainer