import { validString } from 'components/forms/validators'
import * as Yup from 'yup'

export const PersonalSchema = Yup.object().shape({
  lastName: validString('last name').required("Please enter your last name"),
  firstName: validString('first name').required('Please enter your other names'),
  gender: Yup.string().required('Please select a gender')
})

export const PasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required('Required').min(6),
  newPassword: Yup.string().required('Required').min(6, 'Password is too short'),
  repeatNewPassword: Yup.string().required('Required').min(6, 'Password is too short')
})
