import * as Yup from 'yup';
import { validString } from '../validators';

export const CourseSchema = Yup.object().shape({
  name:Yup.string().required("Please enter a course name"),
  image: Yup.string(),
  programme: Yup.string().required("Please assign this course to a programme."),
  code: Yup.string().required("Please enter the course code."),
  level: Yup.string().required("Please assign this course to a level"),
  academicProgrammes: Yup.array().required().min(1, "Please select at least one course"),
  summary: Yup.string().required("Please enter new courses summary."),
  description: Yup.string().required("Please assign this course to a level"),
  content: Yup.string(),
  isCore: Yup.boolean().required()
})

export const CourseInitialValues = {
  name: "",
  summary: "",
  content: "",
  image: "",
  programme: "",
  code: "",
  description: "",
  academicProgrammes: [],
  level: "",
  isCore: false,
}
