import { useEffect } from 'react'
import { wrapClick } from 'utils';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Modal } from 'components';
import { useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import PeopleGroupForm from 'components/forms/people-group';

const GET_RECORD = gql`
query GetPeopleGroup($filter: PeopleGroupFilter!) {
  group:getPeopleGroup(filter: $filter) {
    _id
    name
    slug
    description
  }
}
`;

const UPDATE_RECORD = gql`
mutation UpdatePeopleGroup($id: ID, $input: UpdatePeopleGroupInput) {
  updatePeopleGroup(id: $id, input: $input) {
    _id
  }
}
`

export default function UpdateResearchContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void;}) {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_RECORD, {
    variables: {
      filter: {
        _id: {
          eq: searchParams.id
        }
      }
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'network-only'
  });
  const [updatePeopleGroup, { loading: mutationLoading }] = useMutation(UPDATE_RECORD)

  const form = useFormik({
    initialValues: {
      name: "",
      description: "",
    },
    onSubmit: async (values) => {
      await updatePeopleGroup({
        variables: {
          id: searchParams.id,
          input: {
            ...values,
          }
        }
      }).then(({ data }) => {
        if (data?.updatePeopleGroup?._id) {
          toast(JSON.stringify({ type: "success", title: "Group updated successfully" }))
          refetch?.();
          setOpen(false)
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Could not update group" }))
        }
      })
    },
    onReset: () => {
      setOpen(false)
    }
  })

  useEffect(() => {
    form.setValues({
      name: data?.group?.name || "",
      description: data?.group?.description || ""
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.id, open, data])

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Edit Group Information"
      description="Provide the details to update group"
      loading={loading}
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading || mutationLoading}
            className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {mutationLoading ? "Editing group..." : "Edit group"}
          </button>
        </>
      )}>
      {data?.group?._id && (
        <PeopleGroupForm
          form={form}
        />
      )}
    </Modal>
  )
}