import { withPermissions, wrapClick } from 'utils';
import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { ProgrammeView, Modal } from 'components';
import { useSearch, useNavigate } from 'react-location';
import { LocationGenerics } from 'router/location';
import { currentConfigVar } from 'apollo/cache/config';
import AcademicProgrammeView from 'components/cards/academic-programme-view';

const GET_PROGRAMME = gql`
query GetAcademicProgramme($filter: AcademicProgrammeFilter!, $populate: [String]) {
  programme:getAcademicProgramme(filter: $filter, populate: $populate) {
    _id
    content
    image
    name
    summary
    programme {
      _id
      name
    }
  }
}
`
export default function ViewAcademicProgrammeContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void }) {
  const { pollInterval } = useReactiveVar(currentConfigVar);
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { data, loading } = useQuery(GET_PROGRAMME, {
    variables: {
      filter: {
        _id: {
          eq: searchParams.id
        }
      },
      populate: ["programme"]
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: false
  });
  console.log({data})
  const updateItem = () => {
    navigate({
      search: (old) => ({
        ...old,
        modal: "update"
      })
    })
  }

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Academic Programme Information"
      description="Details of academic programme are shown below"
      loading={loading}
      renderActions={() => (
        <>
          {withPermissions(["*:*", "programmes:*", "programmes:update"])(
            <button
              type="button"
              className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={wrapClick(updateItem)}
            >
              Edit
            </button>
          )}
        </>
      )}
    >
      <AcademicProgrammeView
        data={data?.programme}
      />
    </Modal>
  )
}