import { RichTextArea, TextArea, TextInput, UploadArea } from "components/core"
import { ResearchGroupPicker } from "containers";
import { FormikProps } from "formik"
import { FC } from "react"

export interface ProjectFormProps {
  form: FormikProps<{
    image: string;
    bannerImage: string;
    name: string;
    content: string;
    summary: string;
    researchGroup: string;
  }>;
}

const ProjectForm: FC<ProjectFormProps> = ({ form }) => {

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">Project Information</span>
        <div className="grid grid-cols-1 gap-6 mt-2">

        <div className=" col">
            <UploadArea
              id="image"
              label="Image"
              className="h-44"
              {...form}
              required
            />
          </div>

          <div className=" col">
            <UploadArea
              id="bannerImage"
              label="Banner Image"
              className="h-24"
              {...form}
              required
            />
          </div>

          <div>
            <TextInput
              id="name"
              label="Name"
              type="text"
              placeholder="e.g. Robotics and Motors"
              required
              {...form}
            />
          </div>

          <div>
            <ResearchGroupPicker
              id="researchGroup"
              label="Research Group"
              rawId
              placeholder="e.g. Artificial Intelligence and Generative AI"
              {...form}
            />
          </div>

          <div className="space-y-1">
            <TextArea
              id="summary"
              label="Summary"
              placeholder="e.g. This is a group formed to make inputs in computerized..."
              {...form}
              required
            />
          </div>

          <div className="space-y-1">
            <RichTextArea
              id="content"
              label="Content"
              placeholder="eg. This research group specializes in final computerized radio waves"
              {...form}
            />
          </div>

        </div>
      </div>
    </div>
  )
}

export default ProjectForm
