import { AvatarUpload, MultiCheckInput, SelectInput, TextInput } from "components/core";
import { gql, useQuery } from "@apollo/client";

import { FC } from "react";
import { FormikProps } from "formik";
import _ from "lodash";

interface StaffFormProps {
  form: FormikProps<{
    name: string;
    code: string;
    phone: string;
    gender: string;
    email: string;
    profileImageUrl: string;
    title: string;
    peopleGroups: any[];
    dateJoined: string
  }>;
}
interface GetPeopleGroupsOutputProps {
  getPeopleGroups: {
    _id: string
    name: string
  }[]
}

const GET_PEOPLE_GROUPS = gql`
query GetPeopleGroups($filter: PeopleGroupFilter) {
  getPeopleGroups(filter: $filter) {
    name
    _id
  }
}
`;

const titles = [
  { label: 'Mr', value: 'MR', },
  { label: 'Mrs', value: 'MRS', },
  { label: 'Miss', value: 'MISS', },
  { label: 'DR', value: 'DOCTOR', },
  { label: 'PROF', value: 'PROFESSOR', },
]
const StaffForm: FC<StaffFormProps> = ({ form }) => {
  const { data: peopleGroups } = useQuery<GetPeopleGroupsOutputProps>(GET_PEOPLE_GROUPS, {
    variables: { filter: { deleted: { eq: false } } }
  })
  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">Staff Information</span>
        <div className="grid grid-cols-2 gap-6 mt-2">
          <div>
            <AvatarUpload id="profileImageUrl" label="Photo" {...form} />
          </div>

          <div></div>

          <div>
            <TextInput
              id="code"
              label="Staff ID"
              type="text"
              placeholder="eg. 101314"
              required={true}
              {...form}
            />
          </div>

          <div>
            <SelectInput
              id="title"
              label="Title"
              placeholder="Select Title"
              required={true}
              options={titles}
              {...form}
            />
          </div>

          <div>
            <TextInput
              id="name"
              label="Name"
              type="text"
              placeholder="e.g. Nyankah Mensah"
              required
              {...form}
            />
          </div>

          <div>
            <SelectInput
              id="gender"
              label="Gender"
              placeholder="Select Gender"
              required={true}
              options={[
                { label: "Male", value: "MALE" },
                { label: "Female", value: "FEMALE" },
              ]}
              {...form}
            />
          </div>

          <div>
            <TextInput
              id="phone"
              label="Phone Number"
              type="text"
              placeholder="e.g. 0550123292"
              required
              {...form}
            />
          </div>

          <div>
            <TextInput
              id="dateJoined"
              label="Date Joined"
              type="date"
              placeholder="e.g. 5/4/2002"
              required
              {...form}
            />
          </div>

          <div className="col-span-2">
            <TextInput
              id="email"
              label="Email Address"
              type="email"
              placeholder="e.g. nyankahmensah@gmail.com"
              required
              {...form}

            />
          </div>

          <div className="col-span-2">

            <MultiCheckInput
              label={"People Groups"}
              id="peopleGroups"
              options={peopleGroups?.getPeopleGroups?.map((peopleGroup) => ({ label: _.truncate(peopleGroup?.name, { length: 39 }), value: peopleGroup?._id })) || []}
              {...form}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffForm;
