import {
  RichTextArea,
  SelectInput,
  TextArea,
  TextInput,
  UploadArea,
} from "components/core";
import { SchoolPicker, DepartmentPicker } from "containers";
import { FormikProps } from "formik";
import { FC } from "react";

export interface EventFormProps {
  form: FormikProps<{
    image: string;
    title: string;
    date: string;
    content: string;
    summary: string;
  }>;
}

const AnnouncementForm: FC<EventFormProps> = ({ form }) => {
  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">Announcement Information</span>
        <div className="grid grid-cols-1 gap-6 mt-2">
          <div className=" col">
            <UploadArea
              id="image"
              label="Image"
              className="h-44"
              required
              {...form}
            />
          </div>

          <div>
            <TextInput
              id="title"
              label="Title"
              type="text"
              placeholder="e.g. Resumption of Short Courses"
              required
              {...form}
            />
          </div>

          <div>
            <TextInput id="date" label="Date" type="date" required {...form} />
          </div>

          <div className="space-y-1">
            <TextArea
              id="summary"
              label="Summary"
              placeholder="e.g. We are proud to announce that DCS Short courses are back!"
              required
              {...form}
            />
          </div>

          <div className="space-y-1">
            <RichTextArea
              id="content"
              label="Content"
              placeholder="eg. Announcement Details"
              required
              {...form}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnnouncementForm;
