import { LevelPicker, ProgrammePicker } from "containers";
import { MultiCheckInput, RichTextArea, TextArea, TextInput, UploadArea } from "components/core";

import { FC } from "react";
import { FormikProps } from "formik";
import { Switch } from "@headlessui/react";
import _ from "lodash"
import { classNames } from "utils";

export interface CourseFormProps {
  form: FormikProps<{
    image: string;
    code: string;
    name: string;
    programme: string;
    content: string;
    summary: string;
    level: string;
    isCore: boolean;
    academicProgrammes: string[];
    description: string;
  }>;
  academicProgrammes: {
    name: string;
    _id: string;
  }[];
}

const CourseForm: FC<CourseFormProps> = ({ form, academicProgrammes }) => {
  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">Course Information</span>
        <div className="grid grid-cols-2 gap-6 mt-2">

          <div className="col-span-2">
            <UploadArea
              id="image"
              label="Image"
              className="h-44"
              maxFiles={1}
              {...form}
            />
          </div>

          <div>
            <TextInput
              id="code"
              label="Code"
              type="text"
              placeholder="e.g. DCIT 300"
              required
              {...form}
            />
          </div>


          <div>
            <TextInput
              id="name"
              label="Name"
              type="text"
              placeholder="e.g. Introduction to Computer Science"
              required
              {...form}
            />
          </div>

          <div>
            <ProgrammePicker
              id="programme"
              label="Programme"
              placeholder="Programme Name"
              required={true}
              filter={{}}
              rawId
              {...form}
              setFieldValue={(field: string, value: string) => {
                form.setFieldValue(field, value);
              }}
            />
          </div>

          <div>
            <LevelPicker
              id="level"
              label="Level"
              placeholder="eg. Level 100"
              required={true}
              filter={{}}
              rawId
              {...form}
              setFieldValue={(field: string, value: string) => {
                form.setFieldValue(field, value);
              }}
            />
          </div>
          <div className="">
            <label htmlFor={"isCore"} className="block text-sm font-medium text-gray-700">
              Is this a core course? 
            </label>
            <div className="mt-1">
              <Switch.Group as="div" className="">
                <Switch
                  checked={_.get(form.values, `isCore`)}
                  onChange={(_val: boolean) => form.setFieldValue(`isCore`, _val)}
                  className={classNames(
                    _.get(form.values, "isCore") ? "bg-primary-600" : "bg-gray-200 dark:bg-gray-700",
                    "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      _.get(form.values, "isCore") ? "translate-x-5" : "translate-x-0",
                      "pointer-events-none inline-block h-5 w-5 rounded-full bg-white dark:bg-gray-800 shadow transform ring-0 transition ease-in-out duration-200"
                    )}
                  />
                </Switch>
              </Switch.Group>
            </div>
          </div>

          <div className="col-span-2 ">

            <MultiCheckInput
              id="academicProgrammes"
              label="Academic Programmes"
              required
              itemsPerRow={(academicProgrammes || [])?.length < 3 ? 2 : 4 }
              options={(academicProgrammes || [])?.map((prog) => ({
                label: prog?.name,
                value: prog?._id
              }))}
              {...form}
            />
          </div>

          <div className="space-y-1 col-span-2">
            <TextArea
              id="summary"
              label="Summary"
              placeholder="e.g. This is a complementary programme to Computing"
              {...form}
            />
          </div>

          <div className="space-y-1 col-span-2">
            <TextArea
              id="description"
              label="Description"
              placeholder="eg. A course that focuses primarily on the importance of multi-processing..."
              {...form}
            />
          </div>

          <div className="space-y-1 col-span-2">
            <RichTextArea
              id="content"
              label="Content"
              placeholder="eg. A course that focuses primarily on the importance of multi-processing..."
              {...form}
            />
          </div>

        </div>
      </div>
    </div>
  );
};

export default CourseForm;
