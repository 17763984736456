import { FC } from "react"
import { gql, useMutation, useReactiveVar } from '@apollo/client'
import { currentPushTokenVar, setAuth } from 'apollo/cache/auth';
import { useNavigate, useSearch } from 'react-location';
import { useLocalStorage } from 'react-use';
import { LocationGenerics } from 'router/location';
import * as Yup from 'yup';
import { TextInput } from 'components/core'
import { useFormik } from 'formik'
import { Link } from 'react-location'

const LOGIN_ADMIN = gql`
  mutation LoginUser($input: LoginInput!) {
    auth: loginUser(input: $input) {
      token
      user {
        _id
        code
        email
        phone
        role
        createdAt
        updatedAt
      }
    }
  }
`;

const LoginPage: FC = () => {

  const currentPushToken = useReactiveVar(currentPushTokenVar);

  const search = useSearch<LocationGenerics>()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_forgotStore, setForgotStore] = useLocalStorage<{ username?: string, tab: string, token?: string }>("forgot-store", { username: "", tab: "send-code", token: "" });
  const navigate = useNavigate()
  const [loginUser, { loading }] = useMutation(LOGIN_ADMIN)

  const { handleSubmit, ...form } = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      username: Yup.string().email().required(),
      password: Yup.string().required(),
    }),
    onSubmit: async (values) => {
      await loginUser({
        variables: {
          input: {
            username: values.username.toLowerCase(),
            password: values.password,
            ...(currentPushToken ? { notificationToken: currentPushToken } : {})
          }
        }
      }).then(({ data }) => {
        if (data?.auth) {
          if (data.auth?.user?.meta?.isFirstLogin) {
            setForgotStore({
              tab: "reset-password",
              token: data.auth.token,
              username: data.auth.user.name
            })
            return navigate({
              to: "/forgot-password"
            })
          }
          else {
            setAuth({
              user: data.auth?.user,
              token: data.auth.token,
            })
            return navigate({
              replace: true,
              to: search?.redirect ?? "/"
            })
          }
        }
      })
    }
  })

  return (

    <div className="mx-auto w-full max-w-sm lg:w-96">
      <div className="text-center">
        <h2 className="text-xl font-extrabold text-gray-900">Welcome back!</h2>
        <p className="mt-2 text-sm text-gray-600">
          Enter your account details below.
        </p>
      </div>

      <div className="mt-8">

        <div className="mt-6">
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <TextInput
                id="username"
                label="Email"
                type="email"
                placeholder='e.g. user@ecg.com'
                {...form}
              />
            </div>

            <div className="space-y-1">
              <TextInput
                id="password"
                label="Password"
                type="password"
                placeholder='e.g.  **************'
                {...form}
              />
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
                />
                <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-600">
                  Remember for 30 days
                </label>
              </div>

              <div className="text-sm">
              </div>
            </div>

            <div>
              <button
                type="submit"
                disabled={loading}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
              >
                {loading ? "Logging in..." : "Log in"}
              </button>
            </div>

            <div className="flex flex-col text-center text-sm text-gray-600 space-y-2">
              <span>Don't have an account? <Link to="/forgot-password" className="text-primary-600 hover:text-primary-500">
                Contact Admin
              </Link></span>

              <Link to="/forgot-password" className="text-primary-600 hover:text-primary-500">
                Forgot your password?
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default LoginPage