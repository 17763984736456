import { useQuery, gql } from "@apollo/client"

const GET_TARIFF_CLASS = gql`
  query GetAdmissionCycleBreadcruumb($id: ID!) {
    admissionCycle: getAdmissionCycle(filter: {_id: {eq: $id}}) {
      _id
      code
      name
    }
  }
`
const AdmissionCycleBreadcrumb = ({id}: {id: string}) => {
  const { data } = useQuery(GET_TARIFF_CLASS, {
    variables: {
      id
    },
    notifyOnNetworkStatusChange: false
  })
  return (
    <>
      {data?.admissionCycle?.name || "Tariff"}
    </>
  )
}

export default AdmissionCycleBreadcrumb;