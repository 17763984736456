import { useEffect } from 'react';
import { wrapClick } from 'utils';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Modal } from 'components';
import { useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import CourseForm, { CourseFormProps } from 'components/forms/course';
import { CourseInitialValues, CourseSchema } from 'components/forms/course/schema';

const GET_COURSE = gql`
query GetCourse($filter: CourseFilter!, $populate: [String]) {
  course:getCourse(filter: $filter, populate: $populate) {
    _id
    code
    content
    image
    name
    isCore
    level
    programme {
      _id
      name
    }
    academicProgrammes {
      _id
      name
    }
    summary
    description
  }
}
`;

const UPDATE_COURSE = gql`
mutation UpdateCourse($id: ID!, $input: UpdateCourseInput!) {
  updateCourse(id: $id, input: $input) {
    _id
  }
}
`;

const GET_PROGRAMMES = gql`
  query GetAcademicProgrammesLean {
  programmes: getAcademicProgrammes {
    _id
    name
  }
} 
`;

export default function UpdateCourseContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void; }) {

  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_COURSE, {
    variables: {
      filter: {
        _id: {
          eq: searchParams.id
        }
      },
      populate: ["programme", "academicProgrammes"]
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: false
  });

  const { data: academicProgrammes, loading: programmesLoading } = useQuery(GET_PROGRAMMES, {
    notifyOnNetworkStatusChange: false,
  });

  const [updateCourse, { loading: mutationLoading }] = useMutation(
    UPDATE_COURSE
  );

  const form = useFormik<CourseFormProps["form"]["values"]>({
    initialValues: CourseInitialValues,
    validationSchema: CourseSchema,
    onSubmit: async ({ ...values }) => {
      await updateCourse({
        variables: {
          id: searchParams.id,
          input: {
            ...values
          }
        }
      }).then(({ data }) => {
        if (data.updateCourse._id) {
          toast(JSON.stringify({ type: "success", title: "Course Edited Successfully" }));
          refetch?.();
          setOpen(false);
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Could not create Course" }));
        }
      });
    },
    onReset: () => {
      setOpen(false);
    }
  });

  useEffect(() => {
    form.setValues({
      name: data?.course?.name || "",
      content: data?.course?.content || "",
      summary: data?.course?.summary || "",
      image: data?.course?.image || "",
      code: data?.course?.code || "",
      description: data?.course?.description || "",
      programme: data?.course?.programme?._id || "",
      academicProgrammes: (data?.course?.academicProgrammes?.map((prog: any) => prog?._id))|| [],
      level: data?.course?.level || "",
      isCore: data?.course?.isCore || false
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.course, searchParams.id, open]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Edit Course Information"
      loading={loading}
      description="Provide the details to update course"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={mutationLoading}
            className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {mutationLoading ? "Editing Course..." : "Edit Course"}
          </button>

        </>
      )}>
      {data?.course?._id && (
        <CourseForm
          academicProgrammes={academicProgrammes?.programmes || []}
          form={form}
        />
      )}
    </Modal>
  );
}