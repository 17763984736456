import { useEffect } from 'react'
import { wrapClick } from 'utils';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import {  Modal } from 'components';
import { useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import AcademicProgrammeForm, { AcademicProgrammeFormProps } from 'components/forms/academic-programme';
import { AcademicProgrammeInitialValues, AcademicProgrammeSchema } from 'components/forms/academic-programme/schema';

const GET_PROGRAMME = gql`
query GetAcademicProgramme($filter: AcademicProgrammeFilter!) {
  programme:getAcademicProgramme(filter: $filter) {
    _id
    content
    image
    name
    summary
    programme {
      _id
      name
    }
  }
}
`

const UPDATE_PROGRAMME = gql`
mutation UpdateAcademicProgramme($id: ID!, $input: UpdateAcademicProgrammeInput!) {
  updateAcademicProgramme(id: $id, input: $input) {
    _id
  }
}
`

export default function UpdateAcademicProgrammeContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void;}) {

  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_PROGRAMME, {
    variables: {
      filter: {
        _id: {
          eq: searchParams.id
        }
      },
      populate: ["programme"]

    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: false
  });
  const [updateAcademicProgramme, { loading: mutationLoading }] = useMutation(
    UPDATE_PROGRAMME
  );

  console.log(data)

  const form = useFormik<AcademicProgrammeFormProps["form"]["values"]>({
    initialValues: AcademicProgrammeInitialValues,
    validationSchema: AcademicProgrammeSchema,
    onSubmit: async ({ ...values }) => {
      await updateAcademicProgramme({
        variables: {
          id: searchParams.id,
          input: {
            ...values
          }
        }
      }).then(({ data }) => {
        if (data.updateAcademicProgramme._id) {
          toast(JSON.stringify({ type: "success", title: "Programme Edited Successfully" }))
          refetch?.();
          setOpen(false)
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Could not create Programme" }))
        }
      })
    },
    onReset: () => {
      setOpen(false)
    }
  })

  useEffect(() => {
    form.setValues({
      name: data?.programme?.name || "",
      content: data?.programme?.content || "",
      summary: data?.programme?.summary || "",
      image: data?.programme?.image || "",
      programme: data?.programme?.programme?.name || ""

    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.programme, searchParams.id, open])

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Edit Academic Programme Information"
      description="Provide the details to update academic programme"
      loading={loading}
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading || mutationLoading}
            className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {mutationLoading ? "Editing Programme..." : "Edit Programme"}
          </button>
        </>
      )}>
      {data?.programme?._id && (
        <AcademicProgrammeForm
          form={form}
        />
      )}
    </Modal>
  )
}