import { wrapClick } from 'utils';
import { gql, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Modal } from 'components';
import {  useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { currentConfigVar } from 'apollo/cache/config';

const GET_PROGRAMME = gql`
  query GetProgrammeLean($filter: ProgrammeFilter!, $populate: [String]) {
    programme: getProgramme(filter: $filter, populate: $populate) {
      _id
      name
  }    
}    
`;

const DELETE_PROGRAMME = gql`
  mutation DeleteProgramme($id: ID!) {
    deleteProgramme(id: $id) {
      _id
    }
  }
`

interface DeleteProgrammeContainerProps {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void
}

const DeleteProgrammeContainer: React.FC<DeleteProgrammeContainerProps> = ({ open, setOpen, refetch }) => {
    const { pollInterval } = useReactiveVar(currentConfigVar);
    const searchParams = useSearch<LocationGenerics>();
    const { data, loading } = useQuery(GET_PROGRAMME, {
        variables: {
            filter: {
                _id: {
                    eq: searchParams.id
                },
            },
            populate: []
        },
        notifyOnNetworkStatusChange: false,
        pollInterval
    });
    const [deleteProgramme, { loading: mutationLoading }] = useMutation(DELETE_PROGRAMME)

    const form = useFormik({
        initialValues: {},
        onSubmit: async (values) => {
            // if (data?)
          await deleteProgramme({
            variables: {
              id: searchParams.id,
            }
          }).then(({ data }) => {
              console.log("after delete", data);
              
            if (data?.deleteProgramme?._id) {
              toast(JSON.stringify({ type: "success", title: "Programme deleted successfully" }))
              refetch?.();
              setOpen(false)
            }
            else {
              toast(JSON.stringify({ type: "error", title: "Could not delete programme" }))
            }
          }).catch((error: any) => {
              console.log(error)
          })
        },
        onReset: () => {
          setOpen(false)
        }
      })
    
    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title="Delete Programme"
            description="Remove this programme from the department?"
            size='xl'
            loading={loading}
            renderActions={() => (
                <>
                    <button
                        type="button"
                        disabled={loading || mutationLoading}
                        className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={wrapClick(form.handleSubmit)}
                    >
                        {loading ? "Deleting Programme..." : "Delete Programme"}
                    </button>
                </>
            )}>
            <div>

                <p className="text-sm text-gray-500 text-center">
                    Are you sure you want to delete this admission programme <span className='whitespace-nowrap font-medium text-gray-700'>({data?.programme?.name})</span>? All of your configuration will be permanently removed
                    from our servers forever. This action cannot be undone.
                </p>
            </div>
        </Modal>
    )
}

export default DeleteProgrammeContainer