import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import dayjs from "dayjs";
import numeral from "numeral";
import { FC } from "react";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { classNames } from "utils";

const statsMapGeneral = [
  { name: "Programmes", accessor: "programmes" },
  { name: "Courses", accessor: "courses" },
  { name: "Projects", accessor: "projects" },
  { name: "Research Groups", accessor: "researchGroups" },
  { name: "People Groups", accessor: "peopleGroups" },
  { name: "Staff", accessor: "staff" },
  { name: "Events", accessor: "events" },
  { name: "Announcements", accessor: "announcements" },
  { name: "Users", accessor: "users" },
];

const statsMapCycle = [
  { name: "Programmes", accessor: "admissionProgrammes" },
  { name: "Applicants", accessor: "applicants" },
  { name: "Paid Invoices", accessor: "paidInvoices" },
  { name: "Shortlisted", accessor: "shortlisted" },
  { name: "Admitted", accessor: "admitted" },
];

const DATE_FORMAT = "YYYY-MM-DD";

const OVERVIEW_SUMMARY = gql`
  query Query(
    $programmesFilter: ProgrammeFilter
    $coursesFilter: CourseFilter
    $projectsFilter: ProjectFilter
    $researchGroupsFilter: ResearchGroupFilter
    $staffFilter: StaffFilter
    $eventsFilter: EventFilter
    $announcementsFilter: AnnouncementFilter
  ) {
    programmes: getProgrammesCount(filter: $programmesFilter)
    courses: getCoursesCount(filter: $coursesFilter)
    projects: getProjectCount(filter: $projectsFilter)
    researchGroups: getResearchGroupCount(filter: $researchGroupsFilter)
    peopleGroups: getPeopleGroupCount
    staff: getStaffCount(filter: $staffFilter)
    events: getEventsCount(filter: $eventsFilter)
    announcements: getAnnouncementsCount(filter: $announcementsFilter)
    users: getUsersCount
  }
`;

const OVERVIEW_SUMMARY_CYCLE = gql`
  query Query(
    $admissionProgrammeFilter: AdmissionProgrammeFilter
    $applicationsFilter: ApplicationFilter
    $invoiceFilter: InvoiceFilter
    $shortlistFilter: ShortlistFilter
    $admissionsFilter: AdmissionFilter
  ) {
    admissionProgrammes: getAdmissionProgrammesCount(
      filter: $admissionProgrammeFilter
    )
    applicants: getApplicationsCount(filter: $applicationsFilter)
    paidInvoices: getInvoicesCount(filter: $invoiceFilter)
    shortlisted: getShortlistCount(filter: $shortlistFilter)
    admitted: getAdmissionsCount(filter: $admissionsFilter)
  }
`;

const OverviewContainer: FC = () => {
  const { pollInterval } = useReactiveVar(currentConfigVar);
  const searchParams = useSearch<LocationGenerics>();

  const query = searchParams?.cycle ? OVERVIEW_SUMMARY_CYCLE : OVERVIEW_SUMMARY;

  const cycleFilters = {
    invoiceFilter: {
      cycle: {
        eq: searchParams?.cycle,
      },
      status: {
        eq: "PAID",
      },
    },
    admissionProgrammeFilter: {
      cycle: {
        eq: searchParams?.cycle,
      },
    },
    applicationsFilter: {
      cycle: {
        eq: searchParams?.cycle,
      },
    },
    admissionsFilter: {
      cycle: {
        eq: searchParams?.cycle,
      },
    },
    shortlistFilter: {
      cycle: {
        eq: searchParams?.cycle,
      },
    },
  };

  const generalFilters = {
    programmesFilter: {
      deleted: {
        eq: false,
      },
    },
    coursesFilter: {
      deleted: {
        eq: false,
      },
    },
    projectsFilter: {
      deleted: {
        eq: false,
      },
    },
    researchGroupsFilter: {
      deleted: {
        eq: false,
      },
    },
    peopleGroupsFilter: {
      deleted: false,
    },
    staffFilter: {
      deleted: {
        eq: false,
      },
    },
    eventsFilter: {
      deleted: {
        eq: false,
      },
    },
    announcementsFilter: {
      deleted: {
        eq: false,
      },
    },
  };

  const { data } = useQuery(query, {
    variables: {
      ...(searchParams?.cycle && { ...cycleFilters }),
      ...generalFilters,
    },
    pollInterval,
  });

  console.log(data);

  return (
    <div>
      <h3 className="text-lg font-medium leading-6 text-gray-900">
        System Overview
      </h3>
      <dl className="mt-5 grid grid-cols-1 overflow-hidden rounded bg-white md:grid-cols-3 shadow">
        {!searchParams?.cycle &&
          statsMapGeneral.map((item, index) => (
            <div
              key={item.name}
              className={classNames(
                index !== 0 && index !== 3 ? "border-l border-gray-200" : "",
                index >= 3 ? "border-t border-gray-200" : "",
                "px-4 py-5 sm:p-6",
              )}
            >
              {/* <div className="w-10 h-10 bg-gray-300">

            </div> */}
              <div>
                <dt className="text-sm font-normal text-gray-900">
                  {item.name}
                </dt>
                <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                  <div className="flex items-baseline text-2xl font-semibold text-primary-600">
                    {numeral(data?.[item.accessor] || 0).format(
                      item.accessor === "totalBilled" ? "0,0.00" : "0,0",
                    )}
                  </div>
                </dd>
              </div>
            </div>
          ))}
        {searchParams?.cycle &&
          statsMapCycle.map((item, index) => (
            <div
              key={item.name}
              className={classNames(
                index !== 0 && index !== 3 ? "border-l border-gray-200" : "",
                index >= 3 ? "border-t border-gray-200" : "",
                "px-4 py-5 sm:p-6",
              )}
            >
              {/* <div className="w-10 h-10 bg-gray-300">

            </div> */}
              <div>
                <dt className="text-sm font-normal text-gray-900">
                  {item.name}
                </dt>
                <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                  <div className="flex items-baseline text-2xl font-semibold text-primary-600">
                    {numeral(data?.[item.accessor] || 0).format(
                      item.accessor === "totalBilled" ? "0,0.00" : "0,0",
                    )}
                  </div>
                </dd>
              </div>
            </div>
          ))}
      </dl>
    </div>
  );
};

export default OverviewContainer;

