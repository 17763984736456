import { wrapClick } from 'utils';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Modal } from 'components';
import ProjectForm from 'components/forms/project';
import { ProjectSchema } from 'components/forms/project/schema';




const CREATE_PROJECT = gql`
mutation CreateProject($input: CreateProjectInput!) {
  createProject(input: $input) {
    _id
  }
}
`;

export default function CreateProjectContainer({ open, setOpen, refetch, loading }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void; loading: boolean; }) {

  const [createProject, { loading: mutationLoading }] = useMutation(
    CREATE_PROJECT
  );

  const form = useFormik({
    initialValues: {
      name: "",
      summary: "",
      content: "",
      image: "",
      bannerImage: "",
      researchGroup: "",
    },
    validationSchema: ProjectSchema,
    onSubmit: async (values) => {
      await createProject({
        variables: {
          input: {
            ...values,
          }
        }
      }).then(({ data }) => {
        if (data.createProject._id) {
          toast(JSON.stringify({ type: "success", title: "Project created successfully" }));
          refetch?.();
          form.resetForm();
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Could not create project" }))
        }
      })
    },
    onReset: () => {
      setOpen(false)
    }
  })

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Add New Project"
      description="Provide the details to add a project"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {mutationLoading ? "Adding Project..." : "Add Project"}
          </button>

        </>
      )}>
      <ProjectForm
        form={form}
      />
    </Modal>
  )
}