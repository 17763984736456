import * as Yup from 'yup';

import { validEmail, validPhone, validString } from '../validators';

export const StaffSchema = Yup.object().shape({
  name: validString('staff name').required("Please enter the staff name."),
  code: Yup.string().required("Please enter the staff ID").min(4, "Staff ID is too short").max(24, "Staff ID is too long"),
  gender: Yup.string().required("Please select a gender"),
  phone: validPhone().required('Please enter the staff phone number'),
  email: validEmail().required("Please enter the staff email address"),
  profileImageUrl: Yup.string(),
  title: Yup.string().required("Please enter the staff title"),
  peopleGroups: Yup.array().of(Yup.string()).required("Please select the staff group"),
  dateJoined: Yup.string().required("Please choose the date the staff joined"),
})
