export default [
    {"name": "", "value": "*:*"},

    {"name": "", "value": "programmes:*"},
    {"name": "", "value": "programmes:manage"},
    {"name": "", "value": "programmes:create"},
    {"name": "", "value": "programmes:update"},
    {"name": "", "value": "programmes:import"},
    {"name": "", "value": "programmes:delete"},

    {"name": "", "value": "admins:*"},
    {"name": "", "value": "admins:manage"},
    {"name": "", "value": "admins:create"},
    {"name": "", "value": "admins:update"},
    {"name": "", "value": "admins:import"},
    {"name": "", "value": "admins:delete"},

    {"name": "", "value": "users:*"},
    {"name": "", "value": "users:manage"},
    {"name": "", "value": "users:create"},
    {"name": "", "value": "users:update"},
    {"name": "", "value": "users:import"},
    {"name": "", "value": "users:delete"},

    {"name": "", "value": "staff:*"},
    {"name": "", "value": "staff:manage"},
    {"name": "", "value": "staff:create"},
    {"name": "", "value": "staff:update"},
    {"name": "", "value": "staff:import"},
    {"name": "", "value": "staff:delete"},

    
    {"name": "", "value": "events:*"},
    {"name": "", "value": "events:manage"},
    {"name": "", "value": "events:create"},
    {"name": "", "value": "events:update"},
    {"name": "", "value": "events:import"},
    {"name": "", "value": "events:delete"},

    {"name": "", "value": "people-groups:*"},
    {"name": "", "value": "people-groups:manage"},
    {"name": "", "value": "people-groups:create"},
    {"name": "", "value": "people-groups:update"},
    {"name": "", "value": "people-groups:import"},
    {"name": "", "value": "people-groups:delete"},

    {"name": "", "value": "research-groups:*"},
    {"name": "", "value": "research-groups:manage"},
    {"name": "", "value": "research-groups:create"},
    {"name": "", "value": "research-groups:update"},
    {"name": "", "value": "research-groups:import"},
    {"name": "", "value": "research-groups:delete"},
    
    {"name": "", "value": "courses:*"},
    {"name": "", "value": "courses:manage"},
    {"name": "", "value": "courses:create"},
    {"name": "", "value": "courses:update"},
    {"name": "", "value": "courses:import"},
    {"name": "", "value": "courses:delete"},
    
    {"name": "", "value": "announcements:*"},
    {"name": "", "value": "announcements:manage"},
    {"name": "", "value": "announcements:create"},
    {"name": "", "value": "announcements:update"},
    {"name": "", "value": "announcements:import"},
    {"name": "", "value": "announcements:delete"},
    
    {"name": "", "value": "events:*"},
    {"name": "", "value": "events:manage"},
    {"name": "", "value": "events:create"},
    {"name": "", "value": "events:update"},
    {"name": "", "value": "events:import"},
    {"name": "", "value": "events:delete"},
    
    {"name": "", "value": "projects:*"},
    {"name": "", "value": "projects:manage"},
    {"name": "", "value": "projects:create"},
    {"name": "", "value": "projects:update"},
    {"name": "", "value": "projects:import"},
    {"name": "", "value": "projects:delete"},
] as const;