import { TextInput } from "components/core";
import HodPickerContainer from "containers/hod-picker";

import {
  gql,
  useLazyQuery,
  useQuery,
  // useReactiveVar
} from '@apollo/client';
import { FormikProps } from "formik";
import { FC, useEffect } from "react";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";

interface HodFormProps {
  form: FormikProps<{
    id: string;
    staff: string;
    confirm: string;
    name: string;
    code: string;
    phone: string;
    gender: string;
    email: string;
    profileImageUrl: string;
    title: string;
    peopleGroup: string
  }>;
  isHod: boolean;
}

const titles = [
  { label: 'Mr', value: 'MR', },
  { label: 'Mrs', value: 'MRS', },
  { label: 'Miss', value: 'MISS', },
  { label: 'DR', value: 'DOCTOR', },
  { label: 'PROF', value: 'PROFESSOR', },
]

export const GET_SINGLE_STAFF = gql`
query Query($filter: StaffFilter!) {
  getStaff(filter: $filter) {
    name
    hodMessage
    title
    isHod
    peopleGroup {
      name
    }
    officeHours
    email
    profileImageUrl
  }
}
`

const HodForm: FC<HodFormProps> = ({ form, isHod }) => {
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();


  const [getStaff, { data, loading }] = useLazyQuery(GET_SINGLE_STAFF, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: false
  });

  useEffect(() => {
    if (form?.values?.staff) {
      getStaff({
        variables: {
          filter: {
            _id: {
              eq: form?.values?.staff
            }
          },
        }
      })
    }
  }, [form.values.staff]);

  console.log({getStaff: data?.getStaff})

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div className="col-span-2">
        <HodPickerContainer
          id="staff"
          label="HOD"
          rawId={true}
          {...form}
        />
      </div>

      {
        form.values.staff && (
          <div>
            {
              loading ? <>Loading</> : <>
                <span className="text-xs font-light mt-12">HOD Information</span>
                <div className="grid grid-cols-3 gap-1 mt-2">
                  <div>
                    {
                      data?.getStaff?.profileImageUrl ?
                        <img className="inline-block h-[150px] w-[150px] overflow-hidden rounded-full object-cover" alt="avatar" src={data?.getStaff?.profileImageUrl} />
                        :
                        <span className="inline-block h-[150px] w-[150px] overflow-hidden rounded-full bg-gray-100">
                          <svg
                            className="h-full w-full text-gray-300"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                          </svg>
                        </span>
                    }
                  </div>

                  <div className="bg-white-500 col-span-2 grid grid-cols-2 gap-6 ">
                    <div>
                      <span className="block text-sm font-light text-gray-700">
                        Title
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        <p>{data?.getStaff?.title || "Title Here"}</p>
                      </div>
                    </div>

                    <div>
                      <span className="block text-sm font-light text-gray-700">
                        Name
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        <p>{data?.getStaff?.name || "Title Here"}</p>

                      </div>
                    </div>

                    <div>
                      <span className="block text-sm font-light text-gray-700">
                        Email
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        <p>{data?.getStaff?.email || "Email Here"}</p>
                      </div>
                    </div>

                    <div>
                      <span className="block text-sm font-light text-gray-700">
                        OfficeHours
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        <p>{data?.getStaff?.officeHours || "officeHours Here"}</p>
                      </div>
                    </div>

                  </div>
                </div>



                <div className="grid grid-cols-1 gap-6 mt-4 ">
                  <TextInput
                    id="confirm"
                    type="text"
                    placeholder="Enter HOD to confirm"
                    {...form}
                  />
                </div>

              </>
            }

          </div>
        )
      }

    </div>
  );
};

export default HodForm;