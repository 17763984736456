export function removeTypename(obj: any): any {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(removeTypename);
  }

  const newObj: any = {};
  for (const key in obj) {
    if (key !== '__typename') {
      newObj[key] = removeTypename(obj[key]);
    }
  }

  return newObj;
}


export function trimTextToLength(text: string, maxLength: number) {
  if (typeof text !== 'string') {
    throw new Error('Text must be a string.');
  }

  if (typeof maxLength !== 'number' || maxLength <= 0) {
    throw new Error('Max length must be a positive number.');
  }

  if (text.length <= maxLength) {
    return text;
  }

  return text.slice(0, maxLength - 3) + '...';
}

export function getCurrentDateTime() {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  return `${year}-${month}-${day}T${hours}:${minutes}`;
}