import { FC} from 'react'
import { wrapClick } from 'utils';
import { gql, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Modal } from 'components';
import {useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { currentConfigVar } from 'apollo/cache/config';

const GET_PROJECT = gql`
    query GetProject($filter: ProjectFilter!, $populate: [String]) {
        project:getProject(filter: $filter, populate: $populate) {
          _id
          name
        }
      }
`;

const DELETE_PROJECT = gql`
mutation DeleteProject($id: ID!) {
    deleteProject(id: $id) {
      _id
    }
  }
`

interface DeleteProjectContainerProps {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void
}

const DeleteProjectContainer: FC<DeleteProjectContainerProps> = ({ open, setOpen, refetch }) => {
  const { pollInterval } = useReactiveVar(currentConfigVar);
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_PROJECT, {
    variables: {
      filter: {
        _id: {
          eq: searchParams.id
        }
      },
      populate: []
    },
    notifyOnNetworkStatusChange: false,
    pollInterval
  });
  const [deleteProject, { loading: mutationLoading }] = useMutation(DELETE_PROJECT )

  const form = useFormik({
    initialValues: {},
    onSubmit: async (values) => {
      await deleteProject({
        variables: {
          id: searchParams.id,
        }
      }).then(({ data }) => {
        if (data?.deleteProject?._id) {
          toast(JSON.stringify({ type: "success", title: "Project deleted successfully" }))
          refetch?.();
          setOpen(false)
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Project not delete group" }))
        }
      })
    },
    onReset: () => {
      setOpen(false)
    }
  })

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Delete Project"
      description="Remove this project"
      size='xl'
      loading={loading}
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading || mutationLoading}
            className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading || mutationLoading ? "Deleting Project..." : "Delete Project"}
          </button>
        </>
      )}>
      <div>

        <p className="text-sm text-gray-500 text-center">
          Are you sure you want to delete this project <span className='whitespace-nowrap font-medium text-gray-700'>({data?.project?.name})</span>? All of your configuration will be permanently removed
          from our servers forever. This action cannot be undone.
        </p>
      </div>
    </Modal>
  )
}

export default DeleteProjectContainer;