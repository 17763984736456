import { FC} from 'react'
import { wrapClick } from 'utils';
import { gql, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Modal } from 'components';
import {useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { currentConfigVar } from 'apollo/cache/config';

const GET_ANNOUNCEMENT = gql`
    query GetAnnouncement($filter: AnnouncementFilter!, $populate: [String]) {
        announcement: getAnnouncement(filter: $filter, populate: $populate) {
            _id,
             title
        }
      }
`;

const DELETE_ANNOUNCEMENT = gql`
mutation DeleteAnnouncement($id: ID!) {
    deleteAnnouncement(id: $id) {
        _id,
    }
  }
`

interface DeleteAnnouncementContainerProps {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void
}

const DeleteAnnouncementContainer: FC<DeleteAnnouncementContainerProps> = ({ open, setOpen, refetch }) => {
  const { pollInterval } = useReactiveVar(currentConfigVar);
  const searchParams = useSearch<LocationGenerics>();
  const { data } = useQuery(GET_ANNOUNCEMENT, {
    variables: {
      filter: {
        _id: {
          eq: searchParams.id
        }
      },
      populate: []
    },
    notifyOnNetworkStatusChange: false,
    pollInterval
  });
  const [deleteAnnouncement, { loading }] = useMutation(DELETE_ANNOUNCEMENT )

  const form = useFormik({
    initialValues: {},
    onSubmit: async (values) => {
      await deleteAnnouncement({
        variables: {
          id: searchParams.id,
        }
      }).then(({ data }) => {
        if (data?.deleteAnnouncement?._id) {
          toast(JSON.stringify({ type: "success", title: "Announcement deleted successfully" }))
          refetch?.();
          setOpen(false)
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Could not delete announcement" }))
        }
      })
    },
    onReset: () => {
      setOpen(false)
    }
  })

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Delete Announcement"
      description="Remove this announcement"
      size='xl'
      loading={loading}
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Deleting Announcement..." : "Delete Announcement"}
          </button>
        </>
      )}>
      <div>

        <p className="text-sm text-gray-500 text-center">
          Are you sure you want to delete this announcement <span className='whitespace-nowrap font-medium text-gray-700'>({data?.announcement?.title})</span>? All of your configuration will be permanently removed
          from our servers forever. This action cannot be undone.
        </p>
      </div>
    </Modal>
  )
}

export default DeleteAnnouncementContainer;