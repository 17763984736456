import { useEffect } from 'react'
import { wrapClick } from 'utils';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Modal, EventForm } from 'components';
import { useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { EventSchema } from 'components/forms/event/schema';
import dayjs from 'dayjs';


const GET_EVENT = gql`
query GetEvent($filter: EventFilter!) {
  event:getEvent(filter: $filter) {
    _id
    content
    createdAt
    name
    summary
    image
    datetime
  }
}      
`;

const UPDATE_EVENT = gql`
mutation UpdateEvent($id: ID, $input: UpdateEventInput) {
  updateEvent(id: $id, input: $input) {
    _id
  }
}
`

export default function UpdateEventContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void}) {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_EVENT, {
    variables: {
      filter: {
        _id: {
          eq: searchParams.id
        }
      }
    },
    notifyOnNetworkStatusChange: false
  });
  
  const [updateEvent, { loading: mutationLoading }] = useMutation(
    UPDATE_EVENT
  );


  const form = useFormik({
    initialValues: {
      name: "",
      summary: "",
      content: "",
      image: "",
      datetime: ""
    },
    validationSchema: EventSchema ,
    onSubmit: async (values) => {
      await updateEvent({
        variables: {
          id: searchParams.id,
          input: {
            ...values,
          }
        }
      }).then(({ data }) => {
        if (data?.updateEvent?._id) {
          toast(JSON.stringify({ type: "success", title: "Event updated successfully" }))
          refetch?.();
          setOpen(false)
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Could not update Event" }))
        }
      })
    },
    onReset: () => {
      setOpen(false)
    }
  })

  useEffect(() => {
    form.setValues({
      name: data?.event?.name || "",
      content: data?.event?.content || "",
      image: data?.event?.image || "",
      summary: data?.event?.summary || "",
      datetime: data?.event?.datetime ? dayjs(data?.event?.datetime).format("YYYY-MM-DDTHH:mm") : ""
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.event, searchParams.id, open])

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Edit Event Information"
      description="Provide the details to update event"
      loading={loading}
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={mutationLoading || loading}
            className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {mutationLoading ? "Editing event..." : "Edit event"}
          </button>
        </>
      )}>
      {data?.event?._id && (
        <EventForm
          form={form}
        />
      )}
    </Modal>
  )
}