import { Modal, UserForm } from 'components';
import { gql, useMutation } from '@apollo/client';

import StaffForm from 'components/forms/staff';
import { StaffSchema } from 'components/forms/staff/schema';
import config from 'config';
import toast from 'react-hot-toast';
import { useEffect } from 'react'
import { useFormik } from 'formik';
import { wrapClick } from 'utils';

const CREATE_STAFF = gql`
mutation CreateStaff($input: CreateStaffInput!) {
  createStaff(input: $input) {
    _id
  }
}
`

export default function CreateStaffContainer({ open, setOpen, refetch, loading }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void; role?: string; loading: boolean; }) {
  const [createStaff, { loading: mutationLoading }] = useMutation(CREATE_STAFF)

  const form = useFormik({
    initialValues: {
      name: "",
      code: "",
      phone: "",
      gender: "",
      email: "",
      profileImageUrl: "",
      title: "",
      peopleGroups: [] as any[],
      dateJoined: ""
    },
    validationSchema: StaffSchema,
    onSubmit: async ({ peopleGroups, ...values }) => {
      await createStaff({
        variables: {
          input: {
            ...values,
            ...(peopleGroups && { peopleGroups })
          }
        }
      }).then(({ data }) => {
        if (data.createStaff._id) {
          toast(JSON.stringify({ type: "success", title: "Staff Created Successfully" }));
          refetch?.();
          form.resetForm();
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Could not create Staff" }))
        }
      })
    },
    onReset: () => {
      setOpen(false)
    }
  })

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Add New Staff"
      description="Provide the details to add a new staff"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {mutationLoading ? "Adding Staff..." : "Add Staff"}
          </button>
        </>
      )}>
      <StaffForm
        form={form}
      />
    </Modal>
  )
}
