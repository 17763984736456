import { useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import dayjs from "dayjs";
import { FC } from "react";
import parseHtml from "html-react-parser";
import { classNames } from "utils";

interface AcademicProgrammeViewProps {
    data: {
        code: string;
        name: string;
        summary: string;
        content: string;
        image: string;
        updatedAt: string;
        createdAt: string;
        programme: {
            name: string
            _id: string
        }
    };
}

const AcademicProgrammeView: FC<AcademicProgrammeViewProps> = ({ data }) => {
    const { dateFormat, moneyFormat } = useReactiveVar(currentConfigVar);

    return (
        <div className="space-y-6 divide-y divide-gray-200">
            <div>
                <span className="text-xs font-light">Academic Programme Information</span>
                <div className="grid grid-cols-2 gap-6 mt-2">

                    <div className="col-span-2 h-56 mb-2">
                        <span className="block text-sm font-light text-gray-700">
                            Image
                        </span>
                        {data?.image ? (
                            <img
                                alt={data?.name}
                                src={data?.image}
                                className="w-full h-full object-cover"
                            />
                        ) : (
                            <div
                                className={classNames(
                                    "relative group flex-1 flex border-2 mt-1 h-full border-gray-300 border-dashed rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                )}
                            >
                                <div className="space-y-1 flex flex-1 flex-col items-center justify-center text-center px-6 pt-5 pb-6">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1"
                                        stroke="currentColor"
                                        className="mx-auto h-12 w-12 text-gray-400"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                                        />
                                    </svg>
                                    <div className="flex text-sm justify-center text-gray-600">
                                        <span className="text-center cursor-pointer bg-white rounded-md font-medium text-primary-600 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500">
                                            No Image
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    <div>
                        <span className="block text-sm font-light text-gray-700">Name</span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {data?.name || "N/A"}
                        </div>
                    </div>

                    <div>
                        <span className="block text-sm font-light text-gray-700">Programme</span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {data?.programme?.name || "N/A"}
                        </div>
                    </div>

                    <div className="col-span-2">
                        <span className="block text-sm font-light text-gray-700">
                            Summary
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {data?.summary || "N/A"}
                        </div>
                    </div>

                    <div className="col-span-2">
                        <span className="block text-sm font-light text-gray-700">
                            Content
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {parseHtml(data?.content || "") || "N/A"}
                        </div>
                    </div>
                   
                   

                    <div className='col-span-2'>
                        <span className='text-xs font-light'>Meta Information</span>
                        <div className="grid grid-cols-2  border-gray-300 rounded-md border-dashed border-2 p-4">
                            <div className=''>
                                <span className="block text-sm font-light text-gray-700">
                                    Created At
                                </span>
                                <div className="mt-1 block w-full sm:text-sm">
                                    {dayjs(data?.createdAt).format("DD/MM/YYYY hh:mm A") || "N/A"}
                                </div>
                            </div>

                            <div className=''>
                                <span className="block text-sm font-light text-gray-700">
                                    Updated At
                                </span>
                                <div className="mt-1 block w-full sm:text-sm">
                                    {dayjs(data?.updatedAt).format("DD/MM/YYYY hh:mm A") || "N/A"}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AcademicProgrammeView;
