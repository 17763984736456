import { withPermissions, wrapClick } from 'utils';
import { gql, useQuery } from '@apollo/client';
import { EventView, Modal } from 'components';
import { useSearch, useNavigate } from 'react-location';
import { LocationGenerics } from 'router/location';
import ResearchGroupView from 'components/cards/research-group-view';
import ProjectView from 'components/cards/project-view';

const GET_PROJECT = gql`
query GetProject($filter: ProjectFilter!) {
  project:getProject(filter: $filter) {
    _id
    bannerImage
    content
    createdAt
    updatedAt
    image
    name
    summary
  }
}
`;

export default function ViewResearchGroupContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void }) {
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { data, loading } = useQuery(GET_PROJECT, {
    variables: {
      filter: {
        _id: {
          eq: searchParams.id
        }
      },
      populate: []
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: "network-only"
  });

  const updateItem = () => {
    navigate({
      search: (old) => ({
        ...old,
        modal: "update"
      })
    })
  }

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Project Information"
      description="Details of project are shown below"
      loading={loading}
      renderActions={() => (
        <>
          {withPermissions(["*:*", "events:*", "events:update"])(
            <button
              type="button"
              className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={wrapClick(updateItem)}
            >
              Edit
            </button>
          )}
        </>
      )}
    >
      <ProjectView
        project={data?.project}
      />
    </Modal>
  )
}