import ActionButton, { Action } from 'components/buttons/action-button';
import { ArrowUpTrayIcon, PlusIcon } from '@heroicons/react/24/outline'
import { ExportDataContainer, ImportDataContainer } from 'containers';
import { FC, useEffect, useMemo } from 'react'
import { Header, Shimmers, TableComponent } from 'components';
import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { trimTextToLength, useUrlState, withPermissions, wrapClick } from 'utils';
import { useNavigate, useSearch } from 'react-location';

import { Avatar } from 'components/core';
import CreateResearchGroupContainer from './create';
import DeleteResearchGroupContainer from './delete';
import { LocationGenerics } from 'router/location';
import UpdateResearchGroupContainer from './update';
import ViewResearchGroupContainer from './view';
import config from 'config';
import { currentConfigVar } from 'apollo/cache/config';
import dayjs from 'dayjs';
import lodash from 'lodash';
import numeral from 'numeral';

const GET_RESEARCH_GROUPS = gql`
query GetResearchGroups($filter: ResearchGroupFilter, $pagination: Pagination, $search: SearchOperator, $populate: [String]) {
  items:getResearchGroups(filter: $filter, pagination: $pagination, search: $search, populate: $populate) {
    _id
    name
    createdAt
    updatedAt
    summary
    image
  }
  itemsCount:getResearchGroupCount(filter: $filter, search: $search)
}
`

const ResearchGroupsPage: FC = () => {
  const { pollInterval } = useReactiveVar(currentConfigVar)
  const dispatchAction = (id: string, action: Exclude<Action, ("expand" | "goto" | "clone")>) => () => {
    navigate({
      search: (old) => ({
        ...old,
        id,
        modal: action
      })
    })
  }

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");

  const variables = useMemo(() => ({
    pagination: {
      limit: searchParams.pageSize || 12,
      skip: (searchParams.pageSize || 12) * ((searchParams.page || 1) - 1),
    },
    ...((searchParams?.search || "")?.length > 0 ? {
      search: {
        query: searchParams?.search,
        fields: ["name", 'summary'],
        options: "CaseInsensitive"

      }
    } : {}),
    filter: {

    },
    sort: {

    },
    populate: []
  }), [searchParams]);

  const { data, loading, networkStatus, refetch } = useQuery(GET_RESEARCH_GROUPS, {
    variables,
    // pollInterval,
    notifyOnNetworkStatusChange: false
  });

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? { search: "" } : {}),
        ...(!old?.sort ? { sort: "createdAt" } : {}),
      })
    })
  }, [navigate])

  return (
    <main className="flex-1 flex flex-col overflow-hidden h-screen bg-gray-50">
      <Header
        renderActions={() => (
          <>
            {withPermissions(["*:*", "research-groups:*", "research-groups:create"])(
              <button
                type="button"
                onClick={wrapClick(() => setModal("create"))}
                className="ml-3 inline-flex items-center rounded border border-transparent bg-primary-600 px-3 py-2 text-sm leading-4 text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
              >
                <PlusIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                Add Research Group
              </button>
            )}
          </>
        )}
      />
      <div className='flex flex-1 overflow-y-auto'>
        <div className="flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
          <TableComponent
            title={'research-groups'}
            refetch={refetch}
            isRefetching={loading && networkStatus === 4}
            loading={loading && ![4, 6].includes(networkStatus)}
            data={{
              rows: lodash.get(data, "items", []),
              total: lodash.get(data, "itemsCount", 0),
              page: searchParams?.page || config.constants.page,
              pageSize: searchParams?.pageSize || config.constants.pageSize,
              totalPages: lodash.ceil(lodash.get(data, "itemsCount", 0) / (searchParams?.pageSize || config.constants.pageSize))
            }}
            hasSearch={true}
            renderExport={({ exportOpen, setExportOpen }) => (
              <></>
              // <ExportDataContainer
              //   open={exportOpen}
              //   setOpen={setExportOpen}
              //   entityType="School"
              //   variables={variables}
              // />
            )}
            renderColumns={() => (
              <tr>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Summary
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Created At
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Updated At
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                ></th>
              </tr>
            )}
            renderLoader={() => (
              <tr>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.AvatarShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.ActionsShimmer actionsCount={2} />
                </td>
              </tr>
            )}
            renderItem={(item) => (
              <tr
                key={item._id}
                className="hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer"
                onClick={wrapClick(dispatchAction(item._id, "view"))}
              >
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="font-medium text-gray-900 dark:text-gray-100">
                    {item?.name || "N/A"}
                  </div>
                </td>

                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-500 dark:text-gray-100">
                    {trimTextToLength(item?.summary, 50) || "N/A"}
                  </div>
                </td>

                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-500 dark:text-gray-100">
                    {item?.createdAt
                      ? dayjs(item?.createdAt).format("DD/MM/YYYY hh:mm A")
                      : "N/A"}
                  </div>
                </td>

                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-500 dark:text-gray-100">
                    {item?.createdAt
                      ? dayjs(item?.updatedAt).format("DD/MM/YYYY hh:mm A")
                      : "N/A"}
                  </div>
                </td>

                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right">
                  <div className="space-x-1">
                    <ActionButton
                      action="view"
                      onClick={dispatchAction(item?._id, "view")}
                    />


                    {withPermissions([
                      "*:*",
                      "programmes:*",
                      "programmes:update",
                    ])(
                      <ActionButton
                        action="update"
                        onClick={dispatchAction(item?._id, "update")}
                      />
                    )}

                    {withPermissions([
                      "*:*",
                      "programmes:*",
                      "programmes:delete",
                    ])(
                      <ActionButton
                        action="delete"
                        onClick={dispatchAction(item?._id, "delete")}
                      />
                    )}

                  </div>
                </td>
              </tr>
            )}
          />
        </div>

      </div>

      {withPermissions(["*:*", "research-groups:*", "research-groups:create"])(
        <CreateResearchGroupContainer
          open={modal === "create"}
          setOpen={(val: boolean) => setModal(val ? "create" : undefined)}
          refetch={refetch}
          loading={loading}
        />
      )}
      {withPermissions(["*:*", "research-groups:*", "research-groups:import"])(
        <ImportDataContainer
          open={modal === "import"}
          setOpen={(val: boolean) => setModal(val ? "import" : undefined)}
          entityType={"School"}
        />
      )}
      {!!searchParams.id?.length && (
        <>
          {withPermissions(["*:*", "research-groups:*", "research-groups:update"])(
            <UpdateResearchGroupContainer
              open={modal === "update"}
              setOpen={(val: boolean) => setModal(val ? "update" : undefined)}
              refetch={refetch}
            />
          )}

          {withPermissions(["*:*", "research-groups:*", "research-groups:delete"])(
            <DeleteResearchGroupContainer
              open={modal === "delete"}
              setOpen={(val: boolean) => setModal(val ? "delete" : undefined)}
              refetch={refetch}
            />
          )}



          <ViewResearchGroupContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
          />
        </>
      )}
    </main>
  )
}

export default ResearchGroupsPage;
