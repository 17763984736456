import * as Yup from "yup";

import { validString } from "../validators";

export const ProgrammeSchema = Yup.object().shape({
  name: validString('name').required("Please enter a programme name"),
  image: Yup.string().required("Please choose an image"),
  content: Yup.string().required(
    "Please enter the programme details"
  ),
  summary: Yup.string().required(
    "Please enter the programme summary"
  ),
  expert_instructors: Yup.string().required(
    "Please say something about the expert instructors for website"
  ),
  certification: Yup.string().required(
    "Please say something about the programmes certification"
  )
});

export const ProgrammeInitialValues = {
  name: "",
  summary: "",
  content: "",
  image: "",
  expert_instructors: "",
  certification: ""
};
