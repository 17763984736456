import { wrapClick } from 'utils';
import { gql, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Modal } from 'components';
import {  useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { currentConfigVar } from 'apollo/cache/config';

const GET_COURSE = gql`
  query GetCourseLean($filter: CourseFilter!, $populate: [String]) {
    course: getCourse(filter: $filter, populate: $populate) {
      _id
      name
  }    
}    
`;

const DELETE_COURSE = gql`
  mutation DeleteCourse($id: ID!) {
    deleteCourse(id: $id) {
      _id
    }
  }
`

interface DeleteCourseContainerProps {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void
}

const DeleteCourseContainer: React.FC<DeleteCourseContainerProps> = ({ open, setOpen, refetch }) => {
    const { pollInterval } = useReactiveVar(currentConfigVar);
    const searchParams = useSearch<LocationGenerics>();
    const { data,loading } = useQuery(GET_COURSE, {
        variables: {
            filter: {
                _id: {
                    eq: searchParams.id
                },
                
            },
            populate: []
        },
        notifyOnNetworkStatusChange: false,
        pollInterval
    });
    const [deleteCourse, { loading: mutationLoading }] = useMutation(DELETE_COURSE)

    const form = useFormik({
        initialValues: {},
        onSubmit: async (values) => {
          await deleteCourse({
            variables: {
              id: searchParams.id,
            }
          }).then(({ data }) => {
              
            if (data?.deleteCourse?._id) {
              toast(JSON.stringify({ type: "success", title: "Course deleted successfully" }))
              refetch?.();
              setOpen(false)
            }
            else {
              toast(JSON.stringify({ type: "error", title: "Could not delete course" }))
            }
          }).catch((error: any) => {
              console.log(error)
          })
        },
        onReset: () => {
          setOpen(false)
        }
      })
    
    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title="Delete Course"
            description="Remove this course from the department?"
            size='xl'
            loading={loading}
            renderActions={() => (
                <>
                    <button
                        type="button"
                        disabled={mutationLoading}
                        className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={wrapClick(form.handleSubmit)}
                    >
                        {loading ? "Deleting Course..." : "Delete Course"}
                    </button>
                </>
            )}>
            <div>

                <p className="text-sm text-gray-500 text-center">
                    Are you sure you want to delete this admission course <span className='whitespace-nowrap font-medium text-gray-700'>({data?.course?.name})</span>? All of your configuration will be permanently removed
                    from our servers forever. This action cannot be undone.
                </p>
            </div>
        </Modal>
    )
}

export default DeleteCourseContainer