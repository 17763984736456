import * as Yup from 'yup';
import { validString } from '../validators';

export const AnnouncementSchema = Yup.object().shape({
  image: Yup.string().required('Please select an image'),
  title: validString('announcement title').required('Please enter an announcement title'),
  date: Yup.string().required('Please select a date'),
  summary: validString('announcement summary', 'medium').required('Please enter a brief summary of the announcement'),
  content: validString('content', 'long').required('Please enter the announcement details')
});


