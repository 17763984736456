import { gql, useQuery } from '@apollo/client'
import { FC } from 'react';
import { SearchSelectInput } from 'components/core';

interface PeoplePickerContainerProps {
  filter?: {};
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any
  setFieldValue: any
  rawId?: boolean;
}

const GET_RESEARCH_GROUPS = gql`
query GetResearchGroups($filter: ResearchGroupFilter) {
  groups:getResearchGroups(filter: $filter) {
    _id
    name
  }
}
`;

const ResearchGroupPickerContainer: FC<PeoplePickerContainerProps> = ({ filter, id, label, rawId, ...form }) => {
  const { loading, data } = useQuery(GET_RESEARCH_GROUPS, {
    variables: {
      filter: {
        ...(filter ? filter : {}),
      }
    },
    notifyOnNetworkStatusChange: false
  })

  return (
    <SearchSelectInput
      id={id ?? "group"}
      label={label ?? "Research Group"}
      placeholder="Select Group"
      optionsLoading={loading}
      options={(data?.groups ?? [])?.map((group: any) => ({
        label: {
          title: group.name as string
        },
        value: rawId ? group._id : group
      }))}
      {...form}
      disabled={form.disabled}
    />
  )
}

export default ResearchGroupPickerContainer