import { RichTextArea, TextArea, TextInput, UploadArea } from "components/core"

import { FC } from "react"
import { FormikProps } from "formik"

export interface ProgrammeFormProps {
  form: FormikProps<{
    image: string;
    name: string;
    content: string;
    summary: string;
  }>;
}

const ProgrammeForm: FC<ProgrammeFormProps> = ({ form }) => {

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">Programme Information</span>
        <div className="grid grid-cols-1 gap-6 mt-2">

          <div className=" col">
            <UploadArea
              id="image"
              label="Image"
              className="h-44"
              maxFiles={1}
              required
              {...form}
            />
          </div>

          <div>
            <TextInput
              id="name"
              label="Name"
              type="text"
              placeholder="e.g. Short Courses"
              required
              {...form}
            />
          </div>

          <div className="space-y-1">
            <TextArea
              id="summary"
              label="Summary"
              placeholder="e.g. Our eight week intensive certificate course is open to senior high school graduates, tertiary students, school graduates, workers and the general public."
              required
              {...form}
            />
          </div>

          <div className="space-y-1">
            <RichTextArea
              id="content"
              label="Content"
              placeholder="eg. Programme Details"
              required
              {...form}
            />
          </div>
          <div className="space-y-1">
            <RichTextArea
              id="certification"
              label="Certification"
              placeholder="eg. Describe the certification to this Program"
              required
              {...form}
            />
          </div>
          <div className="space-y-1">
            <RichTextArea
              id="expert_instructors"
              label="Expert Instructors"
              placeholder="eg. Say something about the instructor in this programme"
              required
              {...form}
            />
          </div>

        </div>
      </div>
    </div>
  )
}

export default ProgrammeForm
