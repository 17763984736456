import { AvatarUpload, SelectInput, TextInput } from "components/core";
import { ProgrammePicker, SchoolPicker } from "containers";
import { FormikProps } from "formik";
import { FC } from "react";

interface UserFormProps {
  form: FormikProps<{
    phone: string;
    name: string;
    email: string;
    role: string;
  }>;
}

const userRoles = [
  { label: "Admin", value: "ADMIN" },
  { label: "Sudo", value: "SUDO" },
];

const UserForm: FC<UserFormProps> = ({ form }) => {
  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">User Information</span>
        <div className="grid grid-cols-2 gap-6 mt-2">

          <div>
            <TextInput
              id="name"
              label="Name"
              type="text"
              placeholder="e.g. Elvis Agbesi"
              required
              {...form}
            />
          </div>
          <div>
            <TextInput
              id="phone"
              label="Phone Number"
              type="text"
              placeholder="e.g. 0550123292"
              required
              {...form}
            />
          </div>
          <div className="">
            <TextInput
              id="email"
              label="Email Address"
              type="email"
              placeholder="e.g. nyankahmensah@gmail.com"
              required
              {...form}
            />
          </div>
          <div>
            <SelectInput
              id="role"
              label="Role"
              required={true}
              placeholder="Select Role"
              options={userRoles}
              {...form}
            />
          </div>

        </div>
      </div>
    </div>
  );
};

export default UserForm;
