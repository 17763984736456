
import { FC } from 'react'
import { useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import {
  OverviewContainer,
  ReadersMonthlyOverviewContainer,
  ReadersDailyOverviewContainer
} from './containers';

const DashboardPage: FC = () => {

  const searchParams = useSearch<LocationGenerics>()

  console.log({ searchParams })

  return (
    <div className="flex-1 min-h-full mx-auto  p-4 sm:p-6 overflow-y-auto bg-gray-50">

      <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-12 lg:gap-8">
        <div className='col-span-12'>
          <OverviewContainer />
        </div>
        {/* {["day"].includes(searchParams?.view as string) && (
          <div className='col-span-12'>
            <ReadersDailyOverviewContainer />
          </div>
        )}
        {["month"].includes(searchParams?.view as string) && (
          <div className='col-span-12'>
            <ReadersMonthlyOverviewContainer />
          </div>
        )} */}
      </div>
    </div>
  )
}

export default DashboardPage;
