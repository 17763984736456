import { gql, useMutation, useQuery } from '@apollo/client';

import { LocationGenerics } from 'router/location';
import { Modal } from 'components';
import StaffForm from 'components/forms/staff';
import dayjs from 'dayjs';
import toast from 'react-hot-toast';
import { useEffect } from 'react'
import { useFormik } from 'formik';
import { useSearch } from 'react-location';
import { wrapClick } from 'utils';

export const GET_STAFF = gql`
query GetStaff($filter: StaffFilter!) {
  staff:getStaff(filter: $filter) {
    _id
    code
    email
    gender
    name
    phone
    profileImageUrl
    title
    dateJoined
    peopleGroups {
      _id
      name
    }
  }
}
`

export const UPDATE_STAFF = gql`
mutation UpdateStaff($input: UpdateStaffInput) {
  updateStaff(input: $input) {
    _id
  }
}
`

export default function UpdateStaffContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void }) {

  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_STAFF, {
    variables: {
      filter: {
        _id: {
          eq: searchParams.id
        }
      }
    },
    fetchPolicy: 'no-cache',
  });

  const [updateStaff, { loading: mutationLoading }] = useMutation(UPDATE_STAFF)
  const form = useFormik({
    initialValues: {
      name: "",
      code: "",
      phone: "",
      gender: "",
      email: "",
      profileImageUrl: "",
      title: "",
      peopleGroups: [] as any[],
      dateJoined: ""
    },
    onSubmit: async ({ ...values }) => {
      const input = {
        staffId: searchParams.id,
        ...values
      }
      await updateStaff({
        variables: {
          input
        }
      }).then(({ data }) => {
        if (data.updateStaff._id) {
          toast(JSON.stringify({ type: "success", title: "Staff Edited Successfully" }))
          refetch?.();
          setOpen(false)
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Could not create Staff" }))
        }
      })
    },
    onReset: () => {
      setOpen(false)
    }
  })

  useEffect(() => {
    console.log({ staff: data?.staff })
    form.setValues({
      code: data?.staff?.code || "",
      phone: data?.staff?.phone || "",
      email: data?.staff?.email || "",
      gender: data?.staff?.gender || "",
      name: data?.staff?.name || "",
      profileImageUrl: data?.staff?.profileImageUrl || "",
      title: data?.staff?.title || "",
      peopleGroups: data?.staff?.peopleGroups?.map((group: any) => (group._id)) || [],
      dateJoined: dayjs(data?.data?.staff?.dateJoined).format("YYYY-MM-DD") || ""
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.staff, searchParams.id, open])
  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Edit Staff Information"
      description="Provide the details to update staff"
      loading={loading}
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {mutationLoading || loading ? "Editing Staff..." : "Edit Staff"}
          </button>
        </>
      )}>
      {data?.staff?._id && (
        <StaffForm
          form={form}
        />
      )}
    </Modal>
  )
}