import * as Yup from "yup";
import { validString } from "../validators";

export const EventSchema = Yup.object().shape({
  name: validString("event name").required("Please enter an event name"),
  image: Yup.string().required("Please choose an image"),
  datetime: Yup.date().required("Please choose the event's date and time"),
  summary: Yup.string().required(
    "Please enter the event summary"
  ),
  content: Yup.string().required(
    "Please enter the event details"
  ),
});
