import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { FC, useEffect } from "react";
import { SearchSelectInput } from "components/core";
import { currentUserVar } from "apollo/cache/auth";

interface DepartmentPickerContainerProps {
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any;
  setFieldValue: any;
  rawId?: boolean;
}

const GET_DEPARTMENTS = gql`
  query GetDepartments($filter: DepartmentFilter) {
    getDepartments(filter: $filter, pagination: { skip: 0, limit: 0 }) {
      _id
      code
      name
    }
  }
`;

const DepartmentPickerContainer: FC<DepartmentPickerContainerProps> = ({
  id,
  label,
  rawId,
  ...form
}) => {
  const currentUser = useReactiveVar(currentUserVar);
  const { loading, data } = useQuery(GET_DEPARTMENTS, {
    notifyOnNetworkStatusChange: false,
    variables: {
      filter: form.values?.school ? {
        school: {
          eq: form.values?.school
        }
      } : null
    }
  });


  useEffect(() => {
    if (currentUser?.department) {
      form?.setFieldValue?.(
        id,
        rawId
          ? currentUser.department?._id
          : {
            _id: currentUser.department._id,
            code: currentUser.department.code,
            name: currentUser.department.name,
          }
      );
    }
  }, [currentUser]);

  return (
    <SearchSelectInput
      id={id ?? "department"}
      label={label ?? "department"}
      placeholder="Select Department"
      optionsLoading={loading}
      options={(data?.getDepartments ?? [])?.map((department: any) => ({
        label: {
          title: department.name as string,
        },
        value: rawId ? department?._id : department,
      }))}
      {...form}
      disabled={form.disabled || !!currentUser.department}
    />
  );
};

export default DepartmentPickerContainer;
