import { FC } from "react"
import { EyeIcon, PencilSquareIcon, DocumentDuplicateIcon, CalculatorIcon, ArrowsPointingOutIcon, PaperAirplaneIcon, CalendarDaysIcon, UserPlusIcon, UserIcon, ClipboardDocumentListIcon, ClipboardDocumentCheckIcon, ArrowPathRoundedSquareIcon, MagnifyingGlassPlusIcon, ArrowTopRightOnSquareIcon, Cog8ToothIcon, TrashIcon } from "@heroicons/react/24/outline"
import { classNames, wrapClick } from "utils";
import "react-tooltip/dist/react-tooltip.css"
import { TooltipWrapper } from "react-tooltip";

const Actions = ["update", "delete", "view", "configure", "calculate", "assign", "schedule", "send", "resolve", "approve", "reject", "reassign", "expand", "goto", "clone", "investigate"] as const;
export type Action = typeof Actions[number];
const ActionIcons: { [key in Action]: typeof EyeIcon } = {
  "approve": ClipboardDocumentCheckIcon,
  "assign": UserPlusIcon,
  "calculate": CalculatorIcon,
  "configure": Cog8ToothIcon,
  "reassign": UserIcon,
  "reject": ArrowPathRoundedSquareIcon,
  "resolve": ClipboardDocumentListIcon,
  "schedule": CalendarDaysIcon,
  "send": PaperAirplaneIcon,
  "update": PencilSquareIcon,
  "view": EyeIcon,
  "expand": ArrowsPointingOutIcon,
  "goto": ArrowTopRightOnSquareIcon,
  "clone": DocumentDuplicateIcon,
  "investigate": MagnifyingGlassPlusIcon,
  "delete": TrashIcon
};

interface ActionButtonProps {
  action: Action;
  onClick: (...val: any) => any;
  disabled?: boolean;
};

const ActionButton: FC<ActionButtonProps> = ({ action, onClick, disabled = false }) => {

  const Icon = ActionIcons[action];

  return (
    <TooltipWrapper
      content={action}
      delayShow={1000}
    >
      <button
        type="button"
        onClick={wrapClick(onClick)}
        disabled={disabled}
        className={classNames(
          disabled ? "cursor-not-allowed text-gray-500 hover:bg-gray-300" : "text-gray-500 hover:bg-gray-300 hover:text-gray-900",
          "inline-flex items-center rounded-full border border-transparent p-1  focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
        )}
      >
        <Icon className="h-5 w-5" aria-hidden="true" />
      </button>
    </TooltipWrapper>
  )
}
export default ActionButton;