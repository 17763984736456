import { wrapClick } from 'utils';
import { gql, useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Modal } from 'components';
import PeopleGroupForm from 'components/forms/people-group';
import * as Yup from "yup"
import { PeopleGroupSchema } from 'components/forms/people-group/schema';

const CREATE_MUTATION = gql`
mutation CreatePeopleGroup($input: CreatePeopleGroupInput!) {
  createPeopleGroup(input: $input) {
    _id
    name
  }
}
`;

export default function CreatePeopleGroupContainer({ open, setOpen, refetch, loading, }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void; loading: boolean}) {
 
  const [createMutation, { loading: mutationLoading }] = useMutation(CREATE_MUTATION)

  const form = useFormik({
    initialValues: {
      name: "",
      description: "",
    },
    validationSchema: PeopleGroupSchema, 
    onSubmit: async (values) => {
      await createMutation({
        variables: {
          input: {
            ...values,
          }
        }
      }).then(({ data }) => {
        if (data.createPeopleGroup._id) {
          toast(JSON.stringify({ type: "success", title: "People Group created successfully" }));
          refetch?.();
          form.resetForm();
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Could not create people group" }))
        }
      })
    },
    onReset: () => {
      setOpen(false)
    }
  })

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Add People Group"
      description="Provide the details to add the people group"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {mutationLoading ? "Adding Group..." : "Add Group"}
          </button>
        </>
      )}>
      <PeopleGroupForm
        form={form}
      />
    </Modal>
  )
}
