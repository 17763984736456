import { wrapClick } from 'utils';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Modal, EventForm } from 'components';
import AnnouncementForm from 'components/forms/announcement';
import { AnnouncementSchema } from 'components/forms/announcement/schema';
 

const CREATE_ANNOUNCEMENT = gql`
mutation CreateAnnouncement($input: CreateAnnouncementInput!) {
  createAnnouncement(input: $input) {
    _id
  }
}
`;

export default function CreateAnnouncementContainer({ open, setOpen, refetch, loading }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void; loading: boolean }) {
 
  const [createAnnouncement, { loading: mutationLoading }] = useMutation(
    CREATE_ANNOUNCEMENT
  );

  const form = useFormik({
    initialValues: {
      title: "",
      summary: "",
      content: "",
      image: "",
      date: "",
    },
    validationSchema: AnnouncementSchema ,
    onSubmit: async (values) => {
      await createAnnouncement({
        variables: {
          input: {
            ...values,
          }
        }
      }).then(({ data }) => {
        if (data.createAnnouncement._id) {
          toast(JSON.stringify({ type: "success", title: "Announcement created successfully" }));
          refetch?.();
          form.resetForm();
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Could not create Announcement" }))
        }
      })
    },
    onReset: () => {
      setOpen(false)
    }
  })

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Add New Announcement"
      description="Provide the details to add a new announcement"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {mutationLoading ? "Adding Announcement..." : "Add Announcement"}
          </button>

        </>
      )}>
      <AnnouncementForm
        form={form}
      />
    </Modal>
  )
}