import { wrapClick } from 'utils';
import { gql, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Modal } from 'components';
import CourseForm, { CourseFormProps } from 'components/forms/course';
import { CourseInitialValues, CourseSchema } from 'components/forms/course/schema';
import { LocationGenerics } from 'router/location';
import { currentConfigVar } from 'apollo/cache/config';
import { useSearch } from 'react-location';

const CREATE_COURSE = gql`
mutation CreateCourse($input: CreateCourseInput!) {
  createCourse(input: $input) {
    _id
  }
}
`

const GET_PROGRAMMES = gql`
  query GetAcademicProgrammesLean {
  programmes: getAcademicProgrammes {
    _id
    name
  }
} 
`;

export default function CreateCourseContainer({ open, setOpen, refetch, loading }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void; loading: boolean; }) {
  const { pollInterval } = useReactiveVar(currentConfigVar);
  const searchParams = useSearch<LocationGenerics>();
  const [createCourse, { loading: mutationLoading }] = useMutation(
    CREATE_COURSE
  );

  const { data: academicProgrammes, loading: programmesLoading } = useQuery(GET_PROGRAMMES, {
    notifyOnNetworkStatusChange: false,
    pollInterval
  });


  const form = useFormik<CourseFormProps["form"]["values"]>({
    initialValues: CourseInitialValues,
    validationSchema: CourseSchema,
    onSubmit: async ({ ...values }) => {
      await createCourse({
        variables: {
          input: {
            ...values,
          }
        }
      }).then(({ data }) => {
        if (data.createCourse._id) {
          toast(JSON.stringify({ type: "success", title: "Course Created Successfully" }));
          refetch?.();
          form.resetForm();
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Could not create Course" }))
        }
      })
    },
    onReset: () => {
      setOpen(false)
    }
  })

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Add New Course"
      description="Provide the details to add a new course"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading || programmesLoading}
            className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {mutationLoading ? "Adding Course..." : "Add Course"}
          </button>
        </>
      )}>
      <CourseForm
        form={form}
        academicProgrammes={academicProgrammes?.programmes || []}
      />
    </Modal>
  )
}