import { TextArea, TextInput } from "components/core"
import { FormikProps } from "formik"
import { FC } from "react"

export interface PeopleGroupFormProps {
  form: FormikProps<{
    name: string;
    description: string;
  }>;
}

const PeopleGroupForm: FC<PeopleGroupFormProps> = ({ form }) => {

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">People Group Information</span>
        <div className="grid grid-cols-1 gap-6 mt-2">

          <div>
            <TextInput
              id="name"
              label="Name"
              type="text"
              placeholder="e.g. Robotics and Motors"
              required
              {...form}
            />
          </div>

          <div className="space-y-1">
            <TextArea
              id="description"
              label="Description"
              placeholder="e.g. This is a group formed to make inputs in computerized..."
              {...form}
            />
          </div>

        </div>
      </div>
    </div>
  )
}

export default PeopleGroupForm
