import { wrapClick } from 'utils';
import { gql, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Modal } from 'components';
import { useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { currentConfigVar } from 'apollo/cache/config';

const GET_PEOPLE_GROUP = gql`
  query GetPeopleGroup($filter: PeopleGroupFilter!, $populate: [String]) {
    peopleGroup: getPeopleGroup(filter: $filter, populate: $populate) {
      _id
      name
  }    
}    
`;

const DELETE_PEOPLE_GROUP = gql`
  mutation DeletePeopleGroup($id: ID!) {
    deletePeopleGroup(id: $id) {
      _id
    }
  }
`

interface DeletePeopleGroupsContainerProps {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void
}

const DeletePeopleGroupsContainer: React.FC<DeletePeopleGroupsContainerProps> = ({ open, setOpen, refetch }) => {
  const { pollInterval } = useReactiveVar(currentConfigVar);
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_PEOPLE_GROUP, {
    variables: {
      filter: {
        _id: {
          eq: searchParams.id
        },

      },
      populate: []
    },
    notifyOnNetworkStatusChange: false,
    pollInterval
  });
  const [deletePeopleGroup, { loading: mutationLoading }] = useMutation(DELETE_PEOPLE_GROUP)

  const form = useFormik({
    initialValues: {},
    onSubmit: async (values) => {
      await deletePeopleGroup({
        variables: {
          id: searchParams.id,
        }
      }).then(({ data }) => {
        console.log("after delete", data);

        if (data?.deletePeopleGroup?._id) {
          toast(JSON.stringify({ type: "success", title: "Group deleted successfully" }))
          refetch?.();
          setOpen(false)
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Could not delete people group" }))
        }
      }).catch((error: any) => {
        console.log(error)
      })
    },
    onReset: () => {
      setOpen(false)
    }
  })

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Delete People Group"
      description="Remove this people group from the department?"
      loading={loading || mutationLoading}
      size='xl'
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Deleting Group..." : "Delete Group"}
          </button>
        </>
      )}>
      <div>
        <p className="text-sm text-gray-500 text-center">
          Are you sure you want to delete this people group <span className='whitespace-nowrap font-medium text-gray-700'>({data?.peopleGroup?.name})</span>? All of your configuration will be permanently removed
          from our servers forever. This action cannot be undone.
        </p>
      </div>
    </Modal>
  )
}

export default DeletePeopleGroupsContainer