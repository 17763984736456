import { FC } from 'react'
import { classNames, useDownloadFile, wrapClick } from 'utils';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useFormik } from 'formik';
import _ from 'lodash';
import toast from 'react-hot-toast';
import { Modal } from 'components';

const GET_FIELDS = gql`
  query GetExportFields($entityType: EntityType!) {
    items: getExportFields(entityType: $entityType)
  }
`;

const CREATE_EXPORT = gql`
  query GetExportFileUrl($entityType: EntityType! $fields: [String] $filter: JSON){
    url: getExportFileUrl(entityType: $entityType fields: $fields filter: $filter)
  }
`

interface ExportDataContainerProps {
  entityType: string;
  open: boolean;
  setOpen: (val: boolean) => void;
  variables: any;
}

const ExportDataContainer: FC<ExportDataContainerProps> = ({ open, setOpen, variables: { filter }, entityType }) => {

  const { data } = useQuery(GET_FIELDS, {
    variables: {
      entityType
    }
  })

  const [createDataExport, { loading }] = useLazyQuery(CREATE_EXPORT, {
    fetchPolicy: "no-cache"
  })

  const { downloadAction, downloadLoading } = useDownloadFile({
    onError: () => {
      toast(JSON.stringify({ type: "error", title: "An error occurred downloading file" }))
    },
    getFileName: () => new Date().toISOString() + "_blocks.csv",
  });

  const exportForm = useFormik({
    initialValues: {
      fields: [] as string[],
    },
    onSubmit: (values) => {
      createDataExport({
        variables: {
          entityType,
          fields: values.fields,
          filter: filter,
        },
        fetchPolicy: "no-cache"
      }).then(({ data }) => {
        console.log("datum", data)
        if (data.url) {
          downloadAction(`/public/${data.url}`)
          setOpen(false)
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Could not export file" }))
        }
      })
    }
  })

  const __addField = (field: string) => {
    exportForm.setFieldValue("fields", [...exportForm.values.fields, field])
  }

  const __removeField = (field: string) => {
    exportForm.setFieldValue("fields", _.filter([...exportForm.values.fields], (fieldx) => fieldx !== field))
  }

  const __addAllFields = () => {
    exportForm.setFieldValue("fields", _.reject([...(data?.items || [])], item => item.includes("_id") || item.includes("__v") || item.includes(".$*")))
  }

  const clearForm = () => {
    exportForm.resetForm()
  }

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={`Export ${_.startCase(entityType) || "Data"}`}
      description="Select fields to export"
      size={"5xl"}
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading || downloadLoading}
            className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(() => exportForm.handleSubmit())}
          >
            {loading ? "Exporting data..." : (downloadLoading ? "Downlading file..." : "Export Data")}
          </button>
          <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(() => { __addAllFields() })}
          >
            Select All
          </button>
          <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(() => { clearForm() })}
          >
            Clear All
          </button>
        </>
      )}>
      <div className="grid grid-cols-3 gap-6">
        {_.chain(data?.items)?.reject(item => item.includes("_id") || item.includes("__v") || item.includes(".$*"))?.map((item: string) => {
          const active = exportForm.values.fields.includes(item);
          return (
            <button
              key={item}
              type="button"
              onClick={wrapClick(() => active ? __removeField(item) : __addField(item))}
              className={classNames(
                active ? "border-primary-300 dark:border-primary-600 text-primary-700 dark:text-primary-200" : "border-gray-300 dark:border-gray-600 text-gray-700 dark:text-gray-200",
                "inline-flex w-full items-center px-4 py-2 border  shadow-sm text-sm font-medium rounded  hover:bg-gray-50 dark:hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 truncate"
              )}
            >
              {_.chain(item)?.replace("meta.", "")?.split(".").take(2).map(_.startCase).join(" ").value()}
            </button>
          )
        }).value()}
      </div>
    </Modal>
  )
}

export default ExportDataContainer;