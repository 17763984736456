import { FC } from 'react';
import { wrapClick } from 'utils';
import { gql, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Modal } from 'components';
import { useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { currentConfigVar } from 'apollo/cache/config';

const GET_EVENT = gql`
query GetEvent($filter: EventFilter!) {
  event:getEvent(filter: $filter) {
    _id
    content
    createdAt
    name
    summary
    image
    datetime
  }
}      
`;

const DELETE_EVENT = gql`
mutation DeleteEvent($id: ID!) {
    deleteEvent(id: $id) {
      _id
    }
  }
`;

interface DeleteEventContainerProps {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
}

const DeleteEventContainer: FC<DeleteEventContainerProps> = ({ open, setOpen, refetch }) => {
    const { pollInterval } = useReactiveVar(currentConfigVar);
    const searchParams = useSearch<LocationGenerics>();
    const { data, loading } = useQuery(GET_EVENT, {
        variables: {
            filter: {
                _id: {
                    eq: searchParams.id
                }
            },
            populate: []
        },
        notifyOnNetworkStatusChange: false,
        pollInterval
    });
    const [deleteEvent, { loading: mutationLoading }] = useMutation(DELETE_EVENT);

    const form = useFormik({
        initialValues: {},
        onSubmit: async (values) => {
            await deleteEvent({
                variables: {
                    id: searchParams.id,
                }
            }).then(({ data }) => {
                if (data?.deleteEvent?._id) {
                    toast(JSON.stringify({ type: "success", title: "Event deleted successfully" }));
                    refetch?.();
                    setOpen(false);
                }
                else {
                    toast(JSON.stringify({ type: "error", title: "Could not delete event" }));
                }
            });
        },
        onReset: () => {
            setOpen(false);
        }
    });

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title="Delete Event"
            description="Remove this event"
            size='xl'
            loading={loading}
            renderActions={() => (
                <>
                    <button
                        type="button"
                        disabled={loading || mutationLoading}
                        className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={wrapClick(form.handleSubmit)}
                    >
                        {loading || mutationLoading ? "Deleting Event..." : "Delete Event"}
                    </button>
                </>
            )}>
            <div>

                <p className="text-sm text-gray-500 text-center">
                    Are you sure you want to delete this event <span className='whitespace-nowrap font-medium text-gray-700'>({data?.event?.name})</span>? All of your configuration will be permanently removed
                    from our servers forever. This action cannot be undone.
                </p>
            </div>
        </Modal>
    );
};

export default DeleteEventContainer;