import { useReactiveVar } from '@apollo/client';
import { currentConfigVar } from 'apollo/cache/config';
import dayjs from 'dayjs';
import { FC } from 'react'

interface PeopleGroupViewProps {
  group: {
    code: string;
    name: string;
    description: string
    updatedAt: string
    createdAt: string
  };
}

const PeopleGroupView: FC<PeopleGroupViewProps> = ({ group: data }) => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className='text-xs font-light'>People Group Information</span>
        <div className='grid grid-cols-2 gap-6 mt-2'>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Name
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.name || "N/A"}
            </div>
          </div>

          <div className='col-span-2'>
            <span className="block text-sm font-light text-gray-700">
              Description
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.description || "N/A"}
            </div>
          </div>

          <div className='col-span-2'>
            <span className='text-xs font-light'>Meta Information</span>
            <div className="grid grid-cols-2  border-gray-300 rounded-md border-dashed border-2 p-4">
              <div className=''>
                <span className="block text-sm font-light text-gray-700">
                  Created At
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {dayjs(data?.createdAt).format(dateFormat) || "N/A"}
                </div>
              </div>

              <div className=''>
                <span className="block text-sm font-light text-gray-700">
                  Updated At
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {dayjs(data?.updatedAt).format(dateFormat) || "N/A"}
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  )
}

export default PeopleGroupView