import { withPermissions, wrapClick } from 'utils';
import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { CourseView, Modal } from 'components';
import { useSearch, useNavigate } from 'react-location';
import { LocationGenerics } from 'router/location';
import { currentConfigVar } from 'apollo/cache/config';

const GET_COURSE = gql`
query GetCourse($filter: CourseFilter!, $populate: [String]) {
  course:getCourse(filter: $filter, populate: $populate) {
     _id
    code
    content
    image
    name
    programme {
      _id
      name
    }
    summary
    description
    academicProgrammes {
      _id
      name
    }
    isCore
    level
  }
}
`
export default function ViewCourseContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void }) {
  const { pollInterval } = useReactiveVar(currentConfigVar);
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { data, loading } = useQuery(GET_COURSE, {
    variables: {
      filter: {
        _id: {
          eq: searchParams.id
        }
      },
      populate: ["programme", "academicProgrammes"]
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: false
  });
  console.log({data})
  const updateItem = () => {
    navigate({
      search: (old) => ({
        ...old,
        modal: "update"
      })
    })
  }

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Course Information"
      description="Details of course are shown below"
      loading={loading}
      renderActions={() => (
        <>
          {withPermissions(["*:*", "courses:*", "courses:update"])(
            <button
              type="button"
              className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={wrapClick(updateItem)}
            >
              Edit
            </button>
          )}
        </>
      )}
    >
      <CourseView
        data={data?.course}
      />
    </Modal>
  )
}