import { Modal, ProgrammeView } from 'components';
import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { useNavigate, useSearch } from 'react-location';
import { withPermissions, wrapClick } from 'utils';

import { LocationGenerics } from 'router/location';
import { currentConfigVar } from 'apollo/cache/config';

const GET_PROGRAMME = gql`
query GetProgramme($filter: ProgrammeFilter!) {
  programme:getProgramme(filter: $filter) {
    _id
    content
    image
    name
    summary
    expert_instructors
    certification
  }
}
`
export default function ViewProgrammeContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void }) {
  const { pollInterval } = useReactiveVar(currentConfigVar);
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { data, loading } = useQuery(GET_PROGRAMME, {
    variables: {
      filter: {
        _id: {
          eq: searchParams.id
        }
      },
      populate: []
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: false
  });
  console.log({data})
  const updateItem = () => {
    navigate({
      search: (old) => ({
        ...old,
        modal: "update"
      })
    })
  }

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Programme Information"
      description="Details of programme are shown below"
      loading={loading}
      renderActions={() => (
        <>
          {withPermissions(["*:*", "programmes:*", "programmes:update"])(
            <button
              type="button"
              className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={wrapClick(updateItem)}
            >
              Edit
            </button>
          )}
        </>
      )}
    >
      <ProgrammeView
        data={data?.programme}
      />
    </Modal>
  )
}