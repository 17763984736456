import { Outlet, Route } from "react-location";
import {
  DashboardPage, ProgrammesPage,
  EventsPage, ForgotPasswordPage, SettingsPage,
  SigninPage, UsersPage, PeopleGroupsPage, CoursesPage, AcademicProgrammesPage
} from "pages";
import { BriefcaseIcon, Cog8ToothIcon, ChartBarSquareIcon, BanknotesIcon, MapIcon, DocumentTextIcon, Square3Stack3DIcon,  ArrowRightOnRectangleIcon, AcademicCapIcon, UsersIcon, HomeIcon, CalendarDaysIcon, UserGroupIcon, WrenchScrewdriverIcon, MagnifyingGlassIcon, ExclamationCircleIcon, MegaphoneIcon, UserIcon, BookOpenIcon } from "@heroicons/react/24/outline";
import CalendarLayout from "layouts/calendar-wrapper";
import { UserPermission, initiateClearAuth } from "apollo/cache/auth";
import StaffPage from "pages/staff";
import ResearchGroupsPage from "pages/research-groups";
import ProjectsPage from "pages/projects";
import AnnouncementsPage from "pages/announcements";

export type RouteProps = Omit<Route, "children"> & {
  withPermissions?: UserPermission[];
  navigation?: boolean;
  sidebar?: {
    level: ("primary" | "secondary");
    label: string;
    icon: any;
    onClick?: () => void;
  }
  children?: RouteProps[]
}

const routes: RouteProps[] = [
  {
    path: "/",
    element: <CalendarLayout />,
    sidebar: {
      level: "primary",
      label: "Dashboard",
      icon: ChartBarSquareIcon
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Dashboard",
      section: "General",
    },
    children: [
      {
        id: "all",
        path: "/",
        element: <DashboardPage />,
        meta: {
          layout: "App",
          section: "General",
        },
      },
    ]
  },
  {
    path: "programmes",
    element: <ProgrammesPage />,
    sidebar: {
      level: "primary",
      label: "Programmes",
      icon: AcademicCapIcon
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Programmes Management",
      section: "Structure",
    },
    withPermissions: ["*:*", "programmes:*", "programmes:manage"]
  },
  {
    path: "academic-programmes",
    element: <AcademicProgrammesPage />,
    sidebar: {
      level: "primary",
      label: "Academic Programmes",
      icon: BookOpenIcon
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Academic Programmes Management",
      section: "Structure",
    },
    withPermissions: ["*:*", "programmes:*", "programmes:manage"]
  },
  {
    path: "courses",
    element: <CoursesPage />,
    sidebar: {
      level: "primary",
      label: "Courses",
      icon: Square3Stack3DIcon
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Courses Management",
      section: "Structure",
    },
    withPermissions: ["*:*", "courses:*", "courses:manage"]
  },
  {
    path: "research-groups",
    element: <ResearchGroupsPage />,
    sidebar: {
      level: "primary",
      label: "Research Groups",
      icon: UserGroupIcon
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Research Groups",
      section: "Structure",
    },
    withPermissions: ["*:*", "events:*", "events:manage"]
  },
  {
    path: "people-groups",
    element: <PeopleGroupsPage />,
    sidebar: {
      level: "primary",
      label: "People Groups",
      icon: UsersIcon
    },
    meta: {
      layout: "App",
      breadcrumb: () => "People Groups",
      section: "Structure",
    },
    withPermissions: ["*:*", "people-groups:*", "people-groups:*"]
  },
  {
    path: "events",
    element: <EventsPage />,
    sidebar: {
      level: "primary",
      label: "Events",
      icon: CalendarDaysIcon
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Events Management",
      section: "Structure",
    },
    withPermissions: ["*:*", "events:*", "events:manage"]
  },
  {
    path: "announcements",
    element: <AnnouncementsPage />,
    sidebar: {
      level: "primary",
      label: "Announcements",
      icon: MegaphoneIcon
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Announcement Management",
      section: "Structure",
    },
    withPermissions: ["*:*", "events:*", "events:manage"]
  },
  {
    path: "projects",
    element: <ProjectsPage />,
    sidebar: {
      level: "primary",
      label: "Projects",
      icon: WrenchScrewdriverIcon
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Project Management",
      section: "Structure",
    },
    withPermissions: ["*:*", "events:*", "events:manage"]
  },
  {
    path: "users",
    element: <UsersPage />,
    sidebar: {
      level: "primary",
      label: "Users",
      icon: UserIcon
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Users",
      section: "Users & Profiles",
    },
    withPermissions: ["*:*", "users:*", "users:manage"]
  },
  {
    path: "staff",
    element: <StaffPage />,
    sidebar: {
      level: "primary",
      label: "Staff",
      icon: BriefcaseIcon
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Staff",
      section: "Overview",
    },
    withPermissions: ["*:*", "staff:*", "staff:manage"]
  },
  {
    path: "settings",
    element: <SettingsPage />,
    sidebar: {
      level: "secondary",
      label: "Settings",
      icon: Cog8ToothIcon
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Settings",
      section: "Configuration",
    }
  },
  {
    path: "signout",
    element: <Outlet />,
    sidebar: {
      level: "secondary",
      label: "Logout",
      icon: ArrowRightOnRectangleIcon,
      onClick: initiateClearAuth
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Logout",
      section: "Configuration",
    }
  },
  {
    path: "forgot-password",
    element: <ForgotPasswordPage />,
    meta: {
      layout: "Auth",
      breadcrumb: () => "Forgot Password"
    }
  },
  {
    path: "signin",
    element: <SigninPage />,
    meta: {
      layout: "Auth",
      breadcrumb: () => "Signin"
    }
  },
]

export default routes;
