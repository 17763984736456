import { wrapClick } from 'utils';
import { gql, useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Modal } from 'components';
import HodForm from 'components/forms/hod';
import { LocationGenerics } from 'router/location';
import { useSearch } from 'react-location';
 
const ASSIGN_HOD = gql`
mutation AssignStaffAsHod($input: AssignHodStaffInput) {
  assignStaffAsHod(input: $input) {
    _id
  }
}
`

export default function SelectHodContainer({ open, setOpen, refetch, role, loading }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void; role?: string; loading: boolean;}) {
  const isHod = role === 'HOD';

  const [assignHOD, { loading: mutationLoading }] = useMutation(ASSIGN_HOD)
  const searchParams = useSearch<LocationGenerics>();

  const form = useFormik({
    initialValues: {
      id: searchParams.id || "",
      staff: "",
      name: "",
      code: "",
      phone: "",
      gender: "",
      email: "",
      profileImageUrl: "",
      title:  "",
      peopleGroup:  "",
      confirm: ""
    },
    onSubmit: async ({...values}) => {
      await assignHOD({
        variables: {
          input: {
            staffId: values?.staff
          }
        }
      }).then(({ data }) => {
        if (data.assignStaffAsHod._id) {
          toast(JSON.stringify({ type: "success", title: "HOD Assigned Successfully" }));
          refetch?.();
          form.resetForm();
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Could not assign Staff" }))
        }
      })
    },
    onReset: () => {
      setOpen(false)
    }
  })

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Choose HOD"
      description='Choose and HOD and assign to the staff user'
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading || form.values.confirm !== "HOD"}
            onClick={wrapClick(form.handleSubmit)}
            className="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 disabled:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
          >
            {mutationLoading ? "Assigning Hod..." : "Assign HOD"}
          </button>
        </>
      )}>
      <HodForm
        form={form}
        isHod={isHod}
      />
    </Modal>
  )
}