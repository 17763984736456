import { RichTextArea, TextArea, TextInput, UploadArea } from "components/core";
import { FormikProps } from "formik";
import { FC, useState } from "react";
import { getCurrentDateTime } from "utils";

export interface EventFormProps {
  form: FormikProps<{
    image: string;
    name: string;
    content: string;
    summary: string;
    datetime: string;
  }>;
}

const EventForm: FC<EventFormProps> = ({ form }) => {
  const [minDate, setMinDate] = useState(getCurrentDateTime());

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">Programme Information</span>
        <div className="grid grid-cols-1 gap-6 mt-2">
          <div className=" col">
            <UploadArea
              id="image"
              label="Image"
              className="h-44"
              required
              {...form}
            />
          </div>

          <div>
            <TextInput
              id="name"
              label="Name"
              type="text"
              placeholder="e.g. React Hackathon"
              required
              {...form}
            />
          </div>
          <div>
            <TextInput
              id="datetime"
              label="Date and Time"
              type="datetime-local"
              placeholder="e.g. Short Courses"
              required
              min={minDate}
              {...form}
            />
          </div>
          {/* TODO: Date Validation. This currently does not work onSubmit. See: https://codesandbox.io/p/sandbox/yup-date-validation-g7nxj */}
          <div className="space-y-1">
            <TextArea
              id="summary"
              label="Summary"
              placeholder="e.g. Computer science department hosts a react hackathon to motivate students toward innovation."
              required
              {...form}
            />
          </div>

          <div className="space-y-1">
            <RichTextArea
              id="content"
              label="Content"
              placeholder="eg. A detailed description of the event."
              required
              {...form}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventForm;
