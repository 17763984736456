import { Avatar } from 'components/core';
import { FC } from 'react'
import { classNames } from 'utils';
import dayjs from 'dayjs';

interface StaffViewProps {
  staff: {
    title: string;
    code: string;
    name: string;
    gender: string;
    phone: string;
    email: string;
    profileImageUrl: string;
    createdAt: string,
    updatedAt: string
    dateJoined: string
  };
}

const StaffView: FC<StaffViewProps> = ({ staff }) => {

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className='text-xs font-light'>User Information</span>
        <div className='grid grid-cols-3 gap-6 mt-2'>
          <div className='flex flex-col items-center justify-center'>
            <Avatar
              alt={staff?.name || "user_image"}
              src={staff?.profileImageUrl || ""}
              size="xl"
            />
          </div>

          <div></div>
          <div></div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Title
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {staff?.title || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Name
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {staff?.name || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Staff ID
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {staff?.code || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Email Address
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {staff?.email || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Phone Number
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {staff?.phone || "N/A"}
            </div>
          </div>
          
          <div>
            <span className="block text-sm font-light text-gray-700">
              Date Joined
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {staff?.dateJoined ? dayjs(staff?.dateJoined).format("DD/MM/YYYY "): "N/A"}
            </div>
          </div>


        </div>
      </div>

      <div>
        <span className='text-xs font-light'>Meta Information</span>
        <div className='grid grid-cols-3 gap-6 mt-2'>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Created At
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {staff?.createdAt ? dayjs(staff?.createdAt).format("DD/MM/YYYY hh:mm A") : "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Updated At
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {staff?.updatedAt ? dayjs(staff?.updatedAt).format("DD/MM/YYYY hh:mm A") : "N/A"}
            </div>
          </div>

        </div>
      </div>

    </div>
  )
}

export default StaffView